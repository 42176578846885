import React from "react";
import { Invsvgs, formatterUSD, removeCommaAmount } from "../../helpers/utils";
import { Platformbutton } from "../common/buttons";
import { SmallTitle } from "../common/titlesubtitle";
import { Dashcollapse } from "./dashcomp";
import { useNavigate } from "react-router-dom";

export const SingleinvDash = (props)=> {
    const navigate = useNavigate()
    let cName = (name)=> {
        switch (name){
            case 'Investment':
                return `bg-main-800 border-main-901`;
            case 'Mudarabahh':
                return `bg-[#BB8211] border-[#936710]`;
            case 'Giftc':
                case 'Giftss':
                return `bg-[#219653] border-[#075227]`;
            default:
                return `bg-main-800 border-main-901`;

        }
    }
    let goTo = ()=>{
        navigate(props.link)
    }
    return (
        <div className={`relative min-w-[300px]  md:min-w-[400px] md:w-[450px] h-fit lg:h-[230px] overflow-hidden rounded-[20px] px-[20px] py-[15px] md:px-[40px] md:py-[30px] lg:px-[40px] lg:py-[30px] border-[1.5px] flex  flex-col items-start gap-[30px] ${cName(props.title)}`}>
            <img className="absolute top-0 left-0 right-0 z-0 opacity-50" src={Invsvgs(props.title)} alt={`${props.title} svg`}/>
            <span className="flex flex-col gap-[5px] text-white z-10">
                <p className="my-0 text-[1.25em] font-[300] uppercase">TOTAL {props.title}</p>
                <span className="w-fit flex items-end justify-start gap-[2px] px-0">
                    <p className="my-0 text-[3.5em] font-[700]">{props.value?props.value:0}</p>
                    <p className="my-0 text-[1em] leading-[4em]">/ Packages</p>
                </span>
            </span>
            <Platformbutton classN="fas fa-arrow-right rotate-[-45deg]" addOns={`!z-10`} name="more details" type="withicon" click={goTo} />
            
        </div>
    )
}

export const Totalinv = (props)=> {

    return (
        <div className={`relative w-[350px] md:w-[450px] h-fit overflow-hidden rounded-[20px] px-[20px] py-[15px] md:px-[40px] md:py-[30px] lg:px-[40px] lg:py-[30px] border-[1.5px] flex  flex-col items-start gap-[30px] bg-main-902 border-main-901`}>
            <img className="absolute top-0 left-0 right-0 z-0 opacity-50" src={Invsvgs(props.title)} alt={`${props.title} svg`}/>
            <span className="flex flex-col gap-[0px] text-white z-10">
                <p className="my-0 text-[1.25em] font-[200] uppercase">TOTAL {props.title}</p>
                <span className="w-fit flex flex-col md:flex-row items-start md:items-end justify-start gap-[2px] px-0">
                    <p className="my-0 text-[3.5em] font-[700]">{props.total? props.total:0}</p>
                    <p className="my-0 mt-[-30px] md:mt-0 text-[1em] leading-[4em]">/ Packages</p>
                </span>
            </span>
            <span className="flex flex-col lg:flex-row  items-start gap-[20px]  z-10">
                <span className="flex flex-col gap-[0px]">
                    <p className="my-0 font-[500] text-[.8em] uppercase text-[#FFBE3E] ">Amount Invested</p>
                    <p className="my-0 font-[600] text-[1.2em] uppercase text-white ">₦ {props.amount || 0.00}</p>
                </span>
                <span className="flex flex-col gap-[0px]">
                    <p className="my-0 font-[500] text-[.8em] uppercase text-[#FFBE3E] ">Value of Investment</p>
                    <p className="my-0 whitespace-nowrap font-[600] text-[1em] md:text-[1.2em] uppercase text-white ">₦ {props.value || 0.00}</p>
                </span>
            </span>
        </div>
    )
}

export const Singleinv = (props)=> {
    const nav = useNavigate()

    let handleNav = ()=> {
        console.log(props.details)
        // props.click ? props.click(props.id):  props.type.includes('mudarabah') ? nav(`/mudarabahinvestment/${props.id}`) : nav(`/investment/${props.id}`)
        let url = `/investment?id=${props.code}&code=${props.id}&type=${props.codeName || 'investment'}`
        nav(url)
    }

    let handleTop  =()=> {
        props.topUp(props.id)
    }
    let handleLiqRed  =()=> {
        props.liqRed(props.id)
    }
    return (
        <div className={`relative  ${props.width ? props.width: 'md:w-[450px] xl:w-[450px]' } md:min-w-[400px]  h-fit overflow-hidden rounded-[20px] px-[20px] py-[15px] md:px-[40px] md:py-[30px] lg:px-[40px] lg:py-[30px] border-[1.5px] flex  flex-col items-start gap-[30px] bg-main-900 border-main-901`}>
            <img className="absolute top-[0px] left-0 right-0 z-0 opacity-90 w-full" src={Invsvgs(props.type ? props.type :'single')} alt={`background`}/>
            <div className="flex flex-col gap-[15px] text-white z-10"  >
                <span className="flex flex-col md:flex-row items-start md:items-center gap-[10px]">
                    <p className="my-0 text-[1em] font-[200] uppercase">{props.title}</p>
                    <Growth  value={props.growth} />
                </span>
                <span className="flex flex-col md:flex-row items-start gap-[20px] z-10">
                    <span className="flex flex-col gap-[5px]">
                        <p className="my-0 font-[500] text-[.8em] uppercase text-[#FFBE3E] ">Amount Invested</p>
                        <p className="my-0 font-[600] text-[1.2em] uppercase text-white ">₦ {props.amount? formatterUSD.format(props.amount ):'0.00'}</p>
                    </span> 
                    <span className="flex flex-col gap-[5px]">
                        <p className="my-0 font-[500] text-[.8em] uppercase text-[#FFBE3E] ">Value of Investment</p>
                        <p className="my-0 font-[600] text-[1.2em] uppercase text-white ">₦ {props.value && !isNaN(removeCommaAmount(props.value)) ?props.value:'0.00'}</p>
                    </span>
                </span>
            </div>
           {props.value ? <div className="flex flex-col gap-[15px] z-10">
                {!props.only && <Platformbutton classN={`fas fa-question-circle text-[1em]`} type="withicon" name="Click to see more details" 
                    click = {handleNav}
                />}
                <span className="flex items-start gap-[10px]">
                    <Platformbutton type="dynamic" name="Top Up" 
                    classN={`bg-white h-[50px] text-main-800 px-[30px] py-[20px]`} click={handleTop} />
                   {!props.sub && <Platformbutton type="dynamic" name={props.codeName.includes('mt-dep')?'Liquidate' :"Redeem"}  click={handleLiqRed}
                    classN={`border-[2px] border-white h-[50px] text-white px-[30px] py-[20px]`} />}
                </span>
            </div>: <div className="flex flex-col gap-[15px] h-[80px]  z-10"/>  }
        </div>
    )
}

export const Mudsingleinv = (props)=> {
    const nav = useNavigate()

    let handleNav = ()=> {
        console.log(props.details)
        // props.click ? props.click(props.id):  props.type.includes('mudarabah') ? nav(`/mudarabahinvestment/${props.id}`) : nav(`/investment/${props.id}`)
        let url = `/investment?id=${props.code}&code=${props.id}&type=${props.codeName || 'investment'}`
        nav(url)
    }

    let handleTop  =()=> {
        props.topUp(props.id)
    }
    let handleLiqRed  =()=> {
        props.liqRed(props.id)
    }
    return (
        <div className={`relative  ${props.width ? props.width: 'md:w-[450px] xl:w-[450px]' } md:min-w-[400px]  h-fit overflow-hidden rounded-[20px] px-[20px] py-[15px] md:px-[40px] md:py-[30px] lg:px-[40px] lg:py-[30px] border-[1.5px] flex  flex-col items-start gap-[30px] bg-main-900 border-main-901`}>
            <img className="absolute top-[0px] left-0 right-0 z-0 opacity-90 w-full" src={Invsvgs(props.type ? props.type :'single')} alt={`background`}/>
            <div className="flex flex-col gap-[15px] text-white z-10"  >
                <span className="flex flex-col md:flex-row items-start md:items-center gap-[10px]">
                    <p className="my-0 text-[1em] font-[200] uppercase">{props.title}</p>
                    <Growth  value={props.growth} />
                </span>
                <span className="flex flex-col md:flex-row items-start gap-[20px] z-10">
                    <span className="flex flex-col gap-[5px]">
                        <p className="my-0 font-[500] text-[.8em] uppercase text-[#FFBE3E] ">Amount Invested</p>
                        <p className="my-0 font-[600] text-[1.2em] uppercase text-white ">₦ {props.amount? formatterUSD.format(props.amount ):'0.00'}</p>
                    </span> 
                    <span className="flex flex-col gap-[5px]">
                        <p className="my-0 font-[500] text-[.8em] uppercase text-[#FFBE3E] ">Value of Investment</p>
                        <p className="my-0 font-[600] text-[1.2em] uppercase text-white ">₦ {props.value && !isNaN(removeCommaAmount(props.value)) ?props.value:'0.00'}</p>
                    </span>
                </span>
            </div>
           {props.value ? <div className="flex flex-col gap-[15px] z-10">
                {!props.only && <Platformbutton classN={`fas fa-question-circle text-[1em]`} type="withicon" name="Click to see more details" 
                    click = {handleNav}
                />}
                {/* <span className="flex items-start gap-[10px]">
                    <Platformbutton type="dynamic" name="Top Up" 
                    classN={`bg-white h-[50px] text-main-800 px-[30px] py-[20px]`} click={handleTop} />
                   {!props.sub && <Platformbutton type="dynamic" name={props.codeName.includes('mt-dep')?'Liquidate' :"Redeem"}  click={handleLiqRed}
                    classN={`border-[2px] border-white h-[50px] text-white px-[30px] py-[20px]`} />}
                </span> */}
            </div>: <div className="flex flex-col gap-[15px] h-[80px]  z-10"/>  }
        </div>
    )
}

export const Growth = (props)=> {

    let classStyle = (value)=> {
        if ( value?.toString()?.includes('-') ) {
            return `bg-main-600 ` 
        }
        else {
            return props.value === 0 ?"bg-neutral-600" : `bg-green-800 `
        }
    }
    return (
        <span className={` rounded-[100px] px-[10px] py-[5px] text-white ${classStyle(props.value)} flex items-center gap-[5px]`}>
           {props.value !== 0 && <i className={`fas fa-chevron-${props.value?.toString()?.includes('-') ? 'down':'up'}`}/>}
            <p className={`my-0  text-white text-[.9em] font-[600] flex items-center gap-[5px]`}>  {props.value ? props.value : '0%'} </p>
        </span>
    )
}

export const Addportfolio = (props)=> {

    return (
        <div className="lg:w-[150px] h-full h[260px] lg:h-[256px] px-[30px] lg:py-[80px] flex flex-col items-center justify-center bg-main-100 rounded-[10px] cursor-pointer
        border-[1.5px] border-main-800 border-dashed" onClick={props.click}>
            <i className="fas fa-plus text-[1em] text-main-800"/>
            <p className="my-0 text-[.9em] text-main-800 text-center ">{props.title}</p>
        </div>
    )
}


// investmeent summary 
export const Invsummary = (props)=> {
    const [open, setOpen] = React.useState(true)

    let handleOpenClose = ()=> {
        setOpen(!open)
    }

    return (
    <div className="w-full h-fit flex flex-col gap-[20px]">
        <div className=" w-full flex items-start justify-between">
            <SmallTitle
                    addOns={`!text-[1.2em]`}
                title={props.title}
            />
           {props.control && <i className={`fas fa-chevron-${open === false ? 'down':'up'} text-[1.5em] mt-[2px]`} onClick={handleOpenClose}/>}
        </div>
         
        {props.control ?  open &&  <div className="w-full bg-main-100 border-[1px] border-main-800 rounded-[10px] flex flex-col gap-[20px] px-[30px] py-[30px] ">
            
              {props.data.map((item,index)=> (
                    <span className="relative w-full flex items-center justify-between border-b-[1px] border-b-main-800 border-dashed last:border-b-0 leading-[1.4em]
                    "  key={index.toString()}>
                        <p className="text-[.9em] md:text-[1.2em] font-[400] text-main-902 capitalize ">{item.title}</p>
                        <p className="text-[.8em] md:text-[1.2em] font-[600] text-main-800 text-right ">{item.value}</p>
                    </span>
                ))
            }
            </div>:
            <div className="w-full bg-main-100 border-[1px] border-main-800 rounded-[10px] flex flex-col gap-[20px] px-[30px] py-[30px] ">
                
                {props.data.map((item,index)=> (
                    <span className="relative w-full flex items-center justify-between border-b-[1px] border-b-main-800 border-dashed last:border-b-0 leading-[1.4em]
                    "  key={index.toString()}>
                        <p className="text-[.9em]  md:text-[1.2em] font-[400] text-main-902 capitalize ">{item.title}</p>
                        <p className="text-[.8em]  md:text-[1.2em] font-[600] text-main-800 text-right ">{item.value}</p>
                    </span>
                ))
                }
            </div>
        }

    </div>
    )
}


// investment Details
export const Maininvdetails = (props)=> {

    return (
        <Dashcollapse
            title='Investment Details' addOns="border-[0px]"
            subtitle="All details concerning your investment" noCollapse={true}
        >
        <div className="bg-white w-full  flex flex-wrap gap-[40px] mt-[20px]">
            {
                props.data.map((item,index)=> (
                    <span className="relative w-fit flex flex-col gap-[10px] leading-[1.4em]
                    "  key={index.toString()}>
                        <p className="my-0 text-[].9em]  md:text-[1em] xl:text-[1.2em] font-[400] text-main-600 uppercase ">{item.title}</p>
                        {item.title.toLowerCase() !== 'growth' ?  <p className="my-0 text-[1.2em] font-[600] text-main-902 ">{item.value}</p>:
                            <div className="lg:max-w-[100px]"><Growth value={item.value} type="inc"/></div> 
                        }
                    </span>
                ))
            }
        </div>
        </Dashcollapse>
    )   
   
}

// for account tier & investment benefit 
export const TierndBenefit = (props)=> {
    const [open, setOpen] = React.useState(true)
    let handleOpenClose = ()=> {
        setOpen(!open)
    }
    return (
        <div className=" mt-[20px] flex flex-col gap-[20px] w-full h-fit bg-transparent rounded-[10px]">
            {/* <SmallTitle
                title={props.title}
            /> */}
             <div className=" w-full flex items-start justify-between">
                    <SmallTitle
                         addOns={`!text-[1.2em]`}
                        title={props.title}
                    />
                {props.control && <i className={`fas fa-chevron-${open === false ? 'down':'up'} text-[1.5em] mt-[2px]`} onClick={handleOpenClose}/>}
            </div>
           <span>
            <span className="relative w-full flex flex-col  gap-[10px] items-center justify-center bg-main-700  px-[20px] pb-[30px] pt-[40px] rounded-t-[10px] ">
                {props.popular && <p className="my-0 absolute top-[-10px] left-0 right-0 mx-auto uppercase bg-main-900 rounded-full w-fit text-white px-[10px] py-[5px] ">popular</p>}
                    <p className="font-[700] my-0 lg:text-[2em] text-center leading-[1.2em] uppercase text-main-100 mx-auto lg:max-w-[75%] " >{props.name}</p>
                    {
                        props.mini && <p className="my-0 mx-auto capitalize bg-main-100 rounded-full w-fit text-main-900 px-[10px] py-[5px] ">{props.mini}</p>
                    }
                </span>
                
               { props.control ?  open &&  <div className="relative w-full pt-[20px] bg-main-100 border-[1px] border-main-800 rounded-b-[10px] flex flex-col gap-[20px] px-[30px] py-[15px] ">

                     {props.data.map((item,index)=> (
                            <span className="relative w-full flex items-center justify-between border-b-[1px] border-b-main-800 border-dashed last:border-b-0 leading-[1.2em]
                            "  key={index.toString()}>
                                <p className="lg:max-w-[250px] text-[1em] font-[400] text-main-902 capitalize ">{item.title}</p>
                            {item.value === "check" ?  <i className="fas fa-check-circle text-[1em] text-main-800"/> : <p className="text-[1em] font-[600] text-main-800 uppercase">{item.value}</p>}
                            </span>
                        ))
                    }
                </div>:
                    <div className="relative w-full pt-[20px] bg-main-100 border-[1px] border-main-800 rounded-b-[10px] flex flex-col gap-[20px] px-[30px] py-[15px] ">

                        {props.data.map((item,index)=> (
                            <span className="relative w-full flex items-center justify-between border-b-[1px] border-b-main-800 border-dashed last:border-b-0 leading-[1.2em]
                            "  key={index.toString()}>
                                <p className="lg:max-w-[250px] text-[1em] font-[400] text-main-902 capitalize ">{item.title}</p>
                            {item.value === "check" ?  <i className="fas fa-check-circle text-[1em] text-main-800"/> : <p className="text-[1em] font-[600] text-main-800 uppercase">{item.value}</p>}
                            </span>
                        ))
                        }
                    </div>

                }

           </span>
           

        </div>
   
    )
}