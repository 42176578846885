import { VAL_ACTION, headers } from "../constants";
import api from "../api";
import { showError } from "../../helpers/utils";
import { addApiSuffix } from "../../helpers/helpers";

// this validates anything from TIN, BVN, passports & co, bank info
export const verifyUserInfo = (url,payload, cb,cbe) => {

    return (dispatch) => {
        
      dispatch({ type: VAL_ACTION,
        payload:{valLoad:true,valType:payload.type, valMessage:""},
       });
        api.post(url, payload, headers)
        .then(res => {


            setTimeout(()=> {
                cb && (res.data.data.status.status === 'id_mismatch' ? cbe(res.data): cb(res.data))
            }, 1000000000000)

            if (res.data.data.status.status === 'id_mismatch'){
                cbe(res.data)
                dispatch({ type: VAL_ACTION,payload:{valLoad:false,valType:payload.type, valMessage:''} })
            } else {
                dispatch({ type: VAL_ACTION,payload:{valLoad:false,valType:payload.type, valMessage:''} })
            }
          
        })
        .catch(error => {
             if(cbe) {
                cbe({data:{status:{status:'Error with the id provided'}},  message:showError(error)})
                dispatch({ type: VAL_ACTION,payload:{valLoad:false} });
             } else {
                dispatch({ type: VAL_ACTION,payload:{valLoad:false, valMessage:showError(error)} });
             }
            

        });
    };
};

export const verifyBankInfo = (payload, cb, cbe) => {
  return (dispatch) => {
    dispatch({
      type: VAL_ACTION,
      payload: { valLoad: true, valType: "bank", valMessage: "" },
    });
    api
      .post(addApiSuffix(`Services/ValidateNuban`), payload, headers)
      .then((res) => {
        if (res.data.data.nuban === null) {
          cbe();
        } else {
          setTimeout(() => {
            cb && cb(res.data);
          }, 1000000000000);
        }

        dispatch({
          type: VAL_ACTION,
          payload: { valLoad: false, valType: "bank", valMessage: "" },
        });
      })
      .catch((error) => {
        dispatch({
          type: VAL_ACTION,
          payload: {
            valLoad: false,
            valType: "bank",
            valMessage: error?.data?.message,
          },
        });
      });
  };
};


export const verifyUserService = (url,payload, cb, cbe) => {
  return (dispatch) => {
    dispatch({
      type: VAL_ACTION,
      payload: { valLoad: true, valType: "bank", valMessage: "" },
    });
    api
      .post(addApiSuffix(url), payload, headers)
      .then((res) => {
        if (res.data.data.nuban === null) {
          cbe();
        } else {
          setTimeout(() => {
            cb && cb(res.data);
          }, 1000000000000);
        }

        dispatch({
          type: VAL_ACTION,
          payload: { valLoad: false, valType: "bank", valMessage: "" },
        });
      })
      .catch((error) => {
        dispatch({
          type: VAL_ACTION,
          payload: {
            valLoad: false,
            valType: "bank",
            valMessage: error?.data?.message,
          },
        });
      });
  };
};
