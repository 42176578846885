import React from "react";
import { Selectinput } from "../../../../components/common/inputs/selectinput";
import { AlertError, AlertInfo } from "../../../../components/common/alertinfo";
import { Backcontinue, Platformbutton } from "../../../../components/common/buttons";
import { Toggleview } from "../../../../components/common/toggleview";
import { Inputvalidate, ValidateDocId } from "../../../../components/common/inputs/inputvalidate";
import Singleinput from "../../../../components/common/inputs/singleinput";
import { Phonenumber } from "../../../../components/common/inputs/phoneinput";
import { Formcontainer, Formcol, Formrow, Loader } from "../../../../components/common/common";
import { allEmps } from "../../../../helpers/data";
import { RegEmpTopInfo, Regbankinfo } from "../register";
import { useDispatch } from "react-redux";
import { valAction, authAction } from "../../../../appredux/actions/common";
import { useSelector } from "react-redux";
import { Pickdate } from "../../../../components/common/inputs/datepicker";
import { useForm, useInfo } from "../../../../helpers/hooks";
import { checkAge, fetchAllCountries, fetchAllLgas, fetchAllStates, getCountryCode, getDocType, getLocalStorage, getReligion, removeCommaAmount, setLocalStorage } from "../../../../helpers/utils";
import { SuccVerification, Success } from "../../../../components/common/success";
import { formatPhoneNumber } from "react-phone-number-input";
import { Stepwrapper } from "../../authlayout";
import { getIdOnboard, getAuthApi, addApiSuffix, formatBanks } from "../../../../helpers/helpers";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { isValidPhoneNumber } from 'react-phone-number-input';
import axios from "axios";
import { openNotificationWithIcon } from "../../../../appredux/actions/common";

export const IndieStepOne = (props)=> {

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const [data, setData] = React.useState({
        // email: getLocalStorage('individual')?.email,
        firstName: "",
        lastName: "",
        minorFirstName: "",
        minorLastName: "",
        phoneNumber: '',
        bvn: "",
        isMinor: '',
        //gender: '',
        dateOfBirth: "",
        religion: "",
        stateOfOrigin: "",
        lga: " ",
        countryCode:'',
        //contId:getLocalStorage('userInfo')?.contId, I did
        middleName: "",
        minorMiddleName: "",
       
    })

    const [status,setStatus]= React.useState({
        status:'',
        message:''
    })
    // fetch user details
    const {info, getData}= useInfo(`/api/Contacts/${getIdOnboard()}`);

    let {authLoad,authMessage, valLoad} = useSelector(state => state.common)

    let handleInput = (e)=> {
        let name = e.target.name;
        let value = e.target.value;

        setData({...data, [name]:value})
    }
    let handleName = (e)=> {
        var name = e.target.name;
        var value = e.target.value;
        var newValue = e.target.type === "text"? e.target.value.replace(/[^A-Za-z]/g, ''): value
        setData({...data, [name]:newValue})
    }
    let handleSelect = (name, value)=> {
        setData({...data, [name]:value.label ? value.value: value})
    }   

    let toNextStep = () => {
        if (props.accountType === 1){
            props.moveToUrl('address')
        } else {
            props.moveToUrl('joint')
        }
    }
    
    let nextStep = ()=> {
        setLocalStorage('individual', {personal:data})
        toNextStep()
    }

    // where update was done
    let handleSubmit = ()=> {

        dispatch(authAction({authLoad:true, authMessage:''}))
        const datas = getLocalStorage('bvnData');
               
        const bvnData = {

            firstname: datas.firstName,
            lastname: datas.lastName,
            bvn: datas.bvn
        }
        
        return axios.post("https://lotusapi.lotuscapitallimited.com/api/Tribe/ValidateBvnInDb",bvnData)
          .then((response) => {

           

                setLocalStorage('individual', {personal:data});

                dispatch(authAction({authLoad:false, authMessage:''}))
                props.moveToUrl('address')

            })      
        

        

    }
    React.useEffect(()=> {
        getData()
         // eslint-disable-next-line
    },[])
    React.useEffect(() => {
        const storedData = getLocalStorage('individual')?.personal;
        if (storedData) {
          const parsedData = getLocalStorage('individual')?.personal;
          setData(parsedData);
        }
         // eslint-disable-next-line
      }, []);

    if(info.loading){
        return (
            <div className="w-full flex flex-col gap-[20px]">
                <Loader/>
            </div>
        )
    } else if (info.data?.firstName){
            return (
                <div className="w-full flex  flex-col items-center justify-center gap-[20px]">
                    <Success
                        type = "check"
                        title="Personal Information"
                        subtitle="Great job!  on completing the step! To proceed, simply click the button below."
                        button="Continue"
                        click={toNextStep}

                    />
                </div>
            )
    }else {
        
        return (
            <div className="w-full flex flex-col gap-[20px]">
               {!getLocalStorage('individual')?.personal  && <Selectinput
                    name="isMinor"
                    label="Are you older than 18years?"
                    placeholder="Are you older than 18years?"
                    value={{value:data.isMinor, label: data.isMinor !== '' ? data.isMinor === true ? 'No':'Yes':""}}
                    options={[{value:true, label:'No'},{value:false, label:'Yes'}]}
                    onChange = {handleSelect}
                />}
                {(data.isMinor === true ? <MinorGuardian setData={setData} verifyUserInfo={props.verifyUserInfo}   status={status} setStatus={setStatus} data={data} handleSelect={handleSelect} handleInput={handleInput} handleName={handleName} /> : data.isMinor !== ''&& <Olderinfo handleName={handleName} valLoad={valLoad} verifyUserInfo={props.verifyUserInfo} status={status} setStatus={setStatus} data={data} setData={setData} handleSelect={handleSelect} handleInput={handleInput}/>)}
    
                <span className="w-full flex flex-col items-center gap-[10px]">
                  
                    { <AlertInfo body="Save time and effort by using your BVN to automatically fill in the form for you, making the entire process quicker and more convenient." />}
                    {authMessage && <AlertError body={authMessage} />}
                    {data.isMinor === true ? <Platformbutton name="Continue" type="normal" loading={authLoad} click={handleSubmit} disabled={Object.values(data).slice(0, 13).some(value => value === "") || authLoad || status.status !=='verified' ||!isValidPhoneNumber(data.phoneNumber)  ? true:false } />:
                    <Platformbutton name="Continue" type="normal" click={handleSubmit} 
                        disabled={!data.firstName || !data.lastName || !data.dateOfBirth || !data.bvn ||!data.phoneNumber||!data.religion
                        || !data.stateOfOrigin || !data.lga || authLoad ? true:false ||!isValidPhoneNumber(data.phoneNumber) ? true :false } loading={authLoad}
                    /> }
                </span>
            </div>
        )
    }
    
}

const MinorGuardian = (props)=> {
    const [active, setActive] = React.useState('Guardian')
    
    const dispatch = useDispatch();
    React.useEffect(()=> {
        dispatch(valAction({valMessage:''}))
        // eslint-disable-next-line
    },[])

    
    return (
        <div className="w-full flex flex-col gap-[25px]">
            <span className="w-full flex flex-col gap-[10px]">
                <Toggleview  data={['Guardian information', 'Minor information']} active={active} setActive={setActive} />
                <AlertInfo body="Since you're signing up on behalf of a minor, you are required to kindly  provide your personal information." />
            </span>
            
                
            {
                active.includes('Minor') ? <Minorinfo setData={props.setData}  data={props.data} handleSelect={props.handleSelect} handleInput={props.handleInput}  handleName={props.handleName} /> :<Olderinfo handleName={props.handleName}   status={props.status} setStatus={props.setStatus} setData={props.setData} data={props.data} verifyUserInfo={props.verifyUserInfo} handleSelect={props.handleSelect} handleInput={props.handleInput} type="olderMinor"/>
            }

        </div>
    )
}

const Minorinfo = (props)=> {

    return (

        <Formcontainer>
            <Formrow>
                <Singleinput type="text" name="minorFirstName" value={props.data.minorFirstName} label="First name" placeholder="e.g Sultan" onChange={props.handleName} disabled={false}/>
                <Singleinput type="text" name="minorLastName" value={props.data.minorLastName} label="Last name" placeholder="e.g Sultan" onChange={props.handleName} disbaled={false}/>
               
            </Formrow>
            <Formrow>
            <Singleinput type="text" name="minorMiddleName" value={props.data.minorMiddleName} label="Middle name (optional)" placeholder="e.g Doe" onChange={props.handleName} disabled={false}/>
            <Pickdate value={props.data.dateOfBirth} name="dateOfBirth" label="Date of Birth" onChange={props.handleSelect} height={ `h-[56px] md:h-[50px]`}/>
                
            </Formrow>
            <Selectinput
                name="gender"
                label="Gender"
                value={{value:props.data.gender, label:props.data.gender}}
                options={[{value:'Male', label:'Male'},{value:'Female', label:'Female'}]}
                onChange={props.handleSelect}
            />
            <Formrow>
            <Selectinput
                name="religion"
                label="Religion"
                value={{value:props.data.religion, label:props.data.religion}}
                options={[{value:'Islam', label:'Islam'},{value:'Christianity', label:'Christianity'},{value:'Other', label:'Other'}]}
                onChange={props.handleSelect}
            />
                <Selectinput
                    name="countryCode"
                    label="Country"
                    value={{value:props.data.countryCode, label:props.data.countryCode}}
                    options={fetchAllCountries()}
                    onChange = {props.handleSelect}
                />
            </Formrow>
            {/* <Formrow>
                <Phonenumber
                    name="phoneNumber"
                    value={props.data.phoneNumber}
                    // countries ={['NG']}
                    default="NG"
                    onChange={props.handleInput}
                />
            </Formrow> */}
             <Formrow>
                    <Selectinput
                        name="stateOfOrigin"
                        label="State of Origin"
                        value={{value:props.data.stateOfOrigin, label:props.data.stateOfOrigin}} 
                        options={fetchAllStates(props.data?.countryCode)}
                        onChange={props.handleSelect}
                    />
                    {(props.data.countryCode ==="Nigeria"  && props.data.stateOfOrigin )  && <Selectinput
                        name="lga"
                        label="LGA"
                        value={{value:props.data.lga, label:props.data.lga}}
                        options={fetchAllLgas(props.data.stateOfOrigin)}
                        onChange={props.handleSelect}
                    />}
                </Formrow>
           
        </Formcontainer>
    )
}


const Olderinfo = (props)=> {
    const [overAge, setAge] = React.useState(false)
    let {valLoad} = useSelector(state => state.common)
    
    let handleValidate=()=> {
        setLocalStorage('bvnData', props.data);
        props.verifyUserInfo( addApiSuffix(`Services/ValidateBvn`),{firstName:props.data.firstName, lastName:props.data.lastName, bvn:props.data.bvn},
        (data)=>{
            props.setStatus({status:'verified', message:data.message})
            props.setData({...props.data,middleName:data.data.bvn.middlename,dateOfBirth:props.data.isMinor ?'' : moment(data.data.bvn.birthdate).format("YYYY-MM-DD"), gender:data.data.bvn.gender })}
        ,(data)=>props.setStatus({status:'failed', message:'Verification failed'} ))
    }

    let handleInput=(e)=> {
        props.setStatus({status:'', message:''})
        props.handleInput(e)
    }
     // eslint-disable-next-line 
    let handleDate=(name,value)=> {
        checkAge(value, setAge)
        props.handleSelect(name, value)
    }

    let handleCountry = (name,value)=> {
      
        props.setData({...props.data,countryCode:value.value,stateOfOrigin:'',lga:" " })
    }
    return (

        <Formcontainer>
            <Formcol>  
                <Inputvalidate  onChange={props.handleName} handleInput={handleInput}  bvn={true} name="bvn" label="BVN" 
                    placeholder="e.g 123455678890" disabled={!props.data.firstName || !props.data.lastName || !props.data.bvn || valLoad || props.data.bvn.length < 11 || props.data.bvn.length > 11 ? true:false } 
                    click={handleValidate} data={props.data} value={props.data.bvn}
                />
                <SuccVerification type={props.status.status} />
                    {props.type && <span className="flex flex-col gap-[10px]">
                        <p className="my-0 text-[.75em]">BVN of the adult onboarding required.</p>
                    </span>}
            </Formcol>
            
                
                
            {props.status.status === 'verified' && <>
                
                <Formrow>
                    <Singleinput value={props.data.middleName} name="middleName" label="Middle name(optional)" placeholder="e.g Sultan" onChange={props.handleName} disabled={false}/>
                    {/* <Singleinput value={props.data.email} name="email" label="email" placeholder="Email Address" onChange={props.handleInput} disabled={true}/> */}
                 
                </Formrow>
                <Phonenumber
                    name="phoneNumber"
                    value={props.data.phoneNumber}
                    // countries ={['NG']}
                    default="NG"
                    onChange={props.handleSelect}
                />
            {!props.type && <>
                    <Formcol>
                        <Pickdate value={props.data.dateOfBirth} onChange={handleDate} name="dateOfBirth" label="date of birth" height={ `h-[56px] md:h-[50px]`} disabled={true}/>
                        {(overAge && (props.data.isMinor ===false || props.data.isMinor ==='' || props.data.isMinor === undefined )) && <AlertError body="You are a minor, please choose minor option" />}
                    </Formcol>

                    <Formrow>
                        <Selectinput
                            name="religion"
                            label="Religion"
                            required
                            value={{value:props.data.religion, label:props.data.religion}}
                            options={[{value:'Islam', label:'Islam'},{value:'Christianity', label:'Christianity'},{value:'Other', label:'Other'}]}
                            onChange={props.handleSelect}
                        />
                        <Selectinput
                            name="countryCode"
                            label="Country"
                            required 
                            value={{value:props.data.countryCode, label:props.data.countryCode}} 
                            options={fetchAllCountries()}
                            onChange={handleCountry}
                        />
                    </Formrow>    
                   
                    <Formrow>
                        <Selectinput
                            name="stateOfOrigin"
                            label="State of Origin"
                            value={{value:props.data.stateOfOrigin, label:props.data.stateOfOrigin}} 
                            options={fetchAllStates(props.data?.countryCode)}
                            onChange={props.handleSelect}
                        />
                       {(props.data.countryCode ==="Nigeria"  && props.data.stateOfOrigin )  && <Selectinput
                            name="lga"
                            label="LGA"
                            required
                            value={{value:props.data.lga, label:props.data.lga}}
                            options={fetchAllLgas(props.data.stateOfOrigin)}
                            onChange={props.handleSelect}
                        />}
                    </Formrow>
                    
                </>}
            
                {/* <Selectinput
                    name="joint"
                    label="Is it a joint account ?"
                    value={{value:props.data.joint, label:props.data.joint}}   
                    options={[{value:'No', label:'No'},{value:'Yes', label:'Yes'}]}
                    onChange={props.handleSelect}
                /> */}
            </>}
           
              {/* {((overAge && !props.type) && (props.data.isMinor ===false || props.data.isMinor ==='' || props.data.isMinor === undefined )) && <AlertError body="You are a minor, please choose minor option" />} */}
           </Formcontainer>
    )
}

// joint account

export const JointAccount = (props) => {
    const [status,setStatus]= React.useState({status:'',message:''})
    const [tin,setTin]= React.useState({status:true,message:''})
    const [doc,setDoc]= React.useState({status:'',message:''})

    const {handleInput,handleName, handleSelect,data, setData} = useForm({
        contId: getLocalStorage('userInfo')?.contId,
        emailAddress:'',
        firstName: "",
        lastName: "",
        address: "",
        phoneNumber: '',
        bvn: "",
        gender: '',
        dob: "",
        tin: "",
        idType: "",
        idNumber: "",
        expiryDate: "2030-12-25",
    })

    let nextStep = ()=> {
        var ls = getLocalStorage('individual')
        ls.joint = data
        setLocalStorage('individual', ls)
        props.moveToUrl('address')
    }
    let prevStep = ()=> {
        props.moveToUrl('personal')
    }

    let {valLoad} = useSelector(state => state.common)

    
    let handleValidateTin =()=> {
        props.verifyUserInfo(`api/Services/ValidateTin/${data.tin}`,{type:"tin"},
        (data)=> setTin({status:data.data.status.status, message:data.message}),(data)=> setTin({status:'failed', message:'Verification failed'}) )
    }

    let handleValidate=()=> {
        props.verifyUserInfo( addApiSuffix(`Services/ValidateBvn`),{type:"bvn",firstName:data.firstName, lastName:data.lastName, bvn:data.bvn},
        (res)=>{
            setStatus({status:'verified', message:res.message})
            setData({...data,dob:moment(res.data.bvn.birthdate).format("YYYY-MM-DD"), gender:res.data.bvn.gender })}
        ,(data)=>props.setStatus({status:'failed', message:'Verification failed'} ))
    }

    let handleSubmit = ()=> {
        getLocalStorage('individual')?.joint ? props.continue():    props.addDetails(getAuthApi('AddJointUserInformation'),{...data, phoneNumber:formatPhoneNumber(data.phoneNumber).replace(/\s/g, ""),idNo:data.idNumber,typeOfId:getDocType(data.idType)}, nextStep, 'put')
    }

    return (
        <Stepwrapper title="Joint Account Information" check="firstname" continue={props.continue}
            url={getAuthApi('GetJointAccountDetails','id')}
        >  
        <Formcontainer>
            <Formcol>  
                <Inputvalidate  onChange={handleName} handleInput={handleInput}  bvn={true} name="bvn" label="BVN" 
                    placeholder="e.g 123455678890" disabled={!data.firstName || !data.lastName || !data.bvn || valLoad || data.bvn.length < 11 || data.bvn.length > 11 ? true:false } 
                    click={handleValidate} data={data} value={data.bvn} type="bvn"
                />
                {status.status && <SuccVerification type={status.status} />}
            </Formcol>
            {
                status.status && <>
                    <Formrow>
                        {/* <Singleinput value={data.middleName} name="middleName" label="Middle name(optional)" placeholder="e.g Sultan" onChange={handleName} disabled={true}/> */}
                        <Singleinput value={data.emailAddress} name="emailAddress" label="email" placeholder="Email Address" onChange={handleInput} disabled={false}/>
                    </Formrow>
                    <Phonenumber
                        name="phoneNumber"
                        value={data.phoneNumber}
                        // countries ={['NG']}
                        default="NG"
                        onChange={handleSelect}
                    />
                    <Singleinput value={data.address} name="address" label="Address" placeholder="e.g " onChange={handleInput} disabled={false}/>
                    <span className="w-full flex flex-col gap-[10px]">
                        <Inputvalidate type="tin" message='no' onChange={handleInput} handleInput={handleInput} name="tin" label="Tax Identification Number"
                            placeholder="e.g 123455678890" disabled={!data.tin || valLoad || data.tin.length < 13 || data.tin.length > 13 ? true:false } 
                            click={handleValidateTin} data={data} value={data.tin} />
                        {tin.status && <SuccVerification type={tin.status}/>}
                    </span>

                    <Formcol>
                        <Pickdate value={data.dob} onChange={handleSelect} name="dob" label="date of birth" height={ `h-[56px]`} disabled={true}/>
                        <Singleinput value={data.gender} name="gender" label="Gender" placeholder="e.g " onChange={handleName} disabled={true}/>
                    </Formcol>

                    <ValidateDocId data={{...data, firstname:data.firstName, lastname:data.lastName}} setData={setData} 
                        check={true} status={doc} setStatus={setDoc}
                        handleInput={handleInput} handleSelect={handleSelect} verifyUserInfo={props.verifyUserInfo} />
                    </>
            }
           
            <Formcol>
                {
                    props.authMessage && <AlertError body={props.authMessage} />
                }
                <Backcontinue back={prevStep} continue ={handleSubmit} name="Continue" loading={props.authLoad} disabled={Object.values(data).some(value => value === "") || props.authLoad || tin.status !== "verified" || status.status !== "verified" || doc.status !== "verified" ? true:false } />
           </Formcol>
        </Formcontainer>
        </Stepwrapper>
    )
}

// bank info
export const Bankinfo = (props)=> {
     // eslint-disable-next-line
    const [status, setStatus]= React.useState({
        status:true,
        message:''
    })
    const [doc,setDoc]= React.useState({status:'',message:''})
    const {cloading,allBanks} = useSelector(state=>state.common)
    const {data,setData, handleInput, handleSelect} = useForm({
        // accountNumber: "0122734405",
        // firstname: "Esigie",
        // lastname: "Aguele",
        // accountName:'',
        // bankName: "",
        // bankCode: "062",
        // idType: "",
        // idNumber: "",
        // expiryDate:'2050-12-25',
        
        accountNumber: "",
        firstname: getLocalStorage('individual').personal.firstName,
        lastname: getLocalStorage('individual').personal.lastName,
        accountName:'',
        bankName: "",
        bankCode: "",
        idType: "",
        idNumber: "",
        expiryDate:"",

        //contId: getIdOnboard()
    })

    // const {info, getData}= useInfo(getAuthApi('GetBankDetailsForUser', 'id'))
     const {info, getData}= useInfo(getAuthApi('ValidateNuban'))

    let nextStep = ()=> {

        var ls = getLocalStorage('individual')
        ls.bank = data
       
        setLocalStorage('individual', ls)
        props.moveToUrl('investment')

    }
    let prevStep = ()=> {
        props.moveToUrl('address')
    }

    const reloadPage = () => {

        window.location.reload(false);

      }

    let handleSubmit = ()=> {

       //return window.location = '/auth/register';
       reloadPage();
       
    }
    React.useEffect(()=> {
        
       // console.log("hhhhhhh "+data.verified)
        
         getData()
        props.makeGetAction && props.makeGetAction('Bank', 'allBanks')
         // eslint-disable-next-line
    },[])
    React.useEffect(() => {
        const storedData = getLocalStorage('individual')?.bank;
        if (storedData) {
          const parsedData = getLocalStorage('individual')?.bank;
          setData(parsedData);
        }

         // eslint-disable-next-line
      }, []);
      
       //console.log(formatBanks(allBanks));
   

    let moveForward = ()=> {
        props.moveToUrl('investment')
    }
    if (info.loading || cloading) {
        return (
            <div className="w-full flex flex-col gap-[20px]">
                <Loader/>
            </div>
        )
    } else if (info.data && props.back){
        return (
            <div className="w-full flex  flex-col items-center justify-center gap-[20px]">
                <Success
                    type = "check"
                    title="Bank Information"
                    subtitle="Great job!  on completing the step! To proceed, simply click the button below."
                    button="Continue"
                    click={moveForward}

                />
            </div>
        )
    } else {
        return (
            <Regbankinfo verifyBankInfo={props.verifyBankInfo} handleSelect={handleSelect} handleInput={handleInput} data={data} setData={setData}
                valLoad={props.valLoad}
            >
              

              {data.accountName &&   <ValidateDocId
                    data={{...data, firstname:data.firstname, lastname:data.lastname}} setData={setData} 
                    check={true} status={doc} setStatus={setDoc}
                    handleInput={handleInput} handleSelect={handleSelect} verifyUserInfo={props.verifyUserInfo}
                />}
                <span className="flex flex-col items-start gap-[10px]">
                    {
                        props.authMessage && <AlertError body={props.authMessage} />
                    }
                    {  doc.status !== "verified" }
                    {
                    props.back ? <Backcontinue name="Continue" back= {prevStep} continue={handleSubmit} 
                        disabled={ ((!getLocalStorage('individual') && !getLocalStorage('bankDetails') && data.verified !== 'verified') || (doc.status !== "verified" )) } /> 
                    : 
                    <Platformbutton type="normal" name="Submit"   click={() => props.handleSubmit(data)}
                    disabled={!data.firstname || !data.lastname || data.verified !== 'verified'}  /> 
                     }
                </span>
               
            </Regbankinfo>
        )
    }
   
}



// address info
export const Addressinfo = (props)=> {
    const {info, getData}= useInfo(getAuthApi('GetUserAddress', 'id'))

    const {handleInput,handleSelect,data, setData} = useForm({
        // appUserId: getLocalStorage('userInfo')?.id ||  getLocalStorage('individual')?.id,
        //contId: getLocalStorage('userInfo')?.contId,
        address: "",
        city: "",
        state: "",
        country: ""
    })

    let nextStep = ()=> {
        var ls = getLocalStorage('individual')
        ls.address = data
        setLocalStorage('individual', ls)
        props.moveToUrl('bank')
    }
    let prevStep = ()=> {
        if (props.accountType === 1){
            props.moveToUrl('personal')
        } else {
            props.moveToUrl('personal')
        }
    }
//Where address update takes place
 let handleSubmit = ()=> {

   //console.log({Addressinfo:data})
     setLocalStorage('address', {Addressinfo:data});
     //console.log({Addressinfo:data})
    props.moveToUrl('bank');
       
    }
    // let handleSubmit = ()=> {
    //     if ( getLocalStorage('individual')?.address ){
    //         props.moveToUrl('bank') 

    //     } else {
    //       info.data?.address ? props.updateDetails(`/api/Contacts/UpdateAddressInformation`,data, nextStep) :  props.addDetails(getAuthApi('AddAddressInformation'),data, nextStep)
    //     }
    // }
// n'),data, nextStep)
//         }
//     }
return (
                        <Formcontainer>

                        <Singleinput value={data.address} name="address" label="Residential Address" onChange={handleInput}  placeholder="e.g 182 Awolowo road, Ikoyi" disabled={false}/>
                        <Selectinput
                            name="country"
                            label="Country"
                            value={{value:data.country, label:data.country}}
                            options={fetchAllCountries()}
                            onChange = {handleSelect}
                        />
                        {/* data.country && */}
                        {data.country &&  <Formrow>
                            <Singleinput value={data.city} name="city" onChange={handleInput} label="City" placeholder="e.g " disbaled={false}/>
                            <Selectinput
                                name="state"
                                label="State"
                                value={{value:data.state, label:data.state}}
                                options={fetchAllStates(data.country)}
                                onChange = {handleSelect}
                            />
                        </Formrow>}
                        {/* {props.children}
                        */}
                        <span className="mt-[20px] flex flex-col gap-[10px]">
                            {
                                props.message && <AlertError body={props.message} />
                            }
                            <Backcontinue back={prevStep} continue ={handleSubmit} name="Continue" loading={props.authLoad} disabled={Object.values(data).some(value => value === "") || props.authLoad ? true:false } />
                        </span>
                </Formcontainer>
            // </Stepwrapper>
          
        )
    
}
// next of kiN
export const NextofKin = (props)=> {
    const {info, getData}= useInfo(`api/Contacts/GetNextOfKin/${getLocalStorage('userInfo')?.contId}`)
    const {data,setData, handleInput,handleName,handleSelect} = useForm({
        // title: "Mr",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        // countryCode: "",
        relationship: "",
        emailAddress: "",
        contId: props.id ? props.id:getIdOnboard(),
        middleName: "",
    })
    let nextStep = ()=> {
        
        if (props.id || props.upgrade){
            props.continue()
        } else {
            var ls = getLocalStorage('individual')
            ls.nextOfKin = data
            setLocalStorage('individual', ls)
            props.continue()
        }
    }

    let handleSubmit = ()=> {
        // eslint-disable-next-line
        var payload = {contId:data.contId,emailAddress:data.emailAddress, relationship:data.relationship,  fullname:(data.firstName +" "+ " " + data.middleName + data.lastName),  phoneNumber:formatPhoneNumber(data.phoneNumber).replace(/\s/g, "")}
        if (props.id && !props.upgrade){
            info.data ? props.updateDetails(`api/Contacts/UpdateNextOfKin`,{...payload, id:props.id}, nextStep) : props.addDetails(`api/Contacts/AddNextOfKin`,payload, nextStep)
        } else {
            if ((getLocalStorage('individual')?.nextOfKin)){
                props.continue()
            } else {
                info.data ? props.updateDetails(`api/Contacts/UpdateNextOfKin`,{...payload, id:props.id}, nextStep) :  props.addDetails(`api/Contacts/AddNextOfKin`,payload, nextStep)
            }
            // getLocalStorage('individual').nextOfKin ? props.continue(): props.addDetails(`/nextOfKin/AddNextOfKin`,{...data, phoneNumber:formatPhoneNumber(data.phoneNumber).replace(/\s/g, "")}, nextStep)
        }
        
    }
    React.useEffect(()=>{
        getData()
        // eslint-disable-next-line
    },[])
    React.useEffect(()=> {
        if (info.data){
            setData({ firstName:info.data?.firstName, lastName:info.data?.lastName,middleName:info.data?.middleName,
            phoneNumber:info.data.phoneNumber, relationship:info.data.relationship, emailAddress:info.data.emailAddress})
        }
        // eslint-disable-next-line
    },[info])
    React.useEffect(() => {
        const storedData = getLocalStorage('individual')?.nextOfKin;
        if (storedData) {
          const parsedData = getLocalStorage('individual')?.nextOfKin;
          setData({...parsedData,middleName:parsedData.middleName});
        }
         // eslint-disable-next-line
      }, []);

    return (
        // <Stepwrapper title="Next of Kin" continue={props.continue}
        //         url={`/NextOfKin/${getLocalStorage('userInfo')?.id}/foruser`}
        // >
                <Formcontainer>
                    <Formrow>
                        {/* <Selectinput
                            name="title"
                            label="Title"
                            placeholder="Select Title"
                            value={{value:data.title, label:data.title}}
                            options={[{value:'Mr', label:'Mr'},{value:'Mrs', label:'Mrs'},{value:'Ms', label:'Ms'}]}
                            onChange = {handleSelect}
                        /> */}
                        <Singleinput value={data.firstName} name="firstName" label="First Name" placeholder="e.g Habib" onChange={handleName} disabled={false}/>
                        <Singleinput value={data.lastName} name="lastName" label="Last Name" placeholder="e.g Habib" onChange={handleName} disabled={false}/>
                    </Formrow>
                    <Formrow>
                        <Singleinput value={data.middleName} name="middleName" label="Middle name(optional)" placeholder="e.g Sultan" onChange={handleName} disbaled={false}/>
                        <Singleinput value={data.relationship} name="relationship" label="Relationship" placeholder="e.g Sister" onChange={handleName} disabled={false}/>
                    </Formrow>
                    {/* <Formrow>
                        <Selectinput
                            name="countryCode"
                            label="Country"
                            value={{value:data.countryCode, label:data.countryCode}}
                            options={fetchAllCountries(true)}
                            onChange = {handleSelect}
                        />
                        <Singleinput value={data.relationship} name="relationship" label="Relationship" placeholder="e.g Sister" onChange={handleInput} disabled={false}/>
                    </Formrow> */}
                    <Phonenumber
                        name="phoneNumber"
                        value={data.phoneNumber}
                        // countries ={['NG']}
                        default="NG"
                        onChange={handleSelect}
                        // onChange={(value,code)=> {
                        //     setData({...data, countryCode:code,phoneNumber:value})
                        // }}
                    />
                
                    <Singleinput value={data.emailAddress} name="emailAddress" label="Email" placeholder="e.g Habib@gmail.com" onChange={handleInput} disabled={false}/>
            
                    <span className="w-full mt-[20px] flex flex-col gap-[10px] ">
                        {
                            props.authMessage && <AlertError body={props.authMessage} />
                        }
                        <Backcontinue name="Continue" back={ props.back} continue={handleSubmit}  loading={props.authLoad}
                            disabled={Object.values(data).slice(0, 6).some(value => value === "") || props.authLoad ||!isValidPhoneNumber(data.phoneNumber) ? true:false }
                        /> 
                    </span>
                </Formcontainer>
        // </Stepwrapper>
        
    )
}

// employment detail
export const Employmentinfo = (props)=> {
    const [status, setStatus] = React.useState({
        status:'',
        message:''
    })
    const {data,setData, handleInput,handleSelect}= useForm(
        {
            employmentStatus: "",
            occupation: "",
            annualIncomeRange: 0,
            industryType: "",
            employerName: "",
            taxIdentificationNumber: "",
            employerAddress: "",
            employerPhoneNumber: "",
            employerCountryCode: "",
            employerCity: "",
            employerState: "",
            sourceOfIncome: "",
            instituitionName: "",
            instituitionAddress: "",
            instituitonCountry: "",
            instituitionCity: "",
            instituitionState: "Abia",
            businessName: "",
            businessCountry:'',
            businessState:'',
            businessCity:'',
            businessAddress: "",
            businessPhoneNumber: "",
            lastEmployerName: "",
            lastEmployerAddress:'',
            lastEmployerPhoneNumber: "",
            lastEmployerState: "",
            lastEmployerCity: "",
            lastEmployerCountry: "",
            lastEmployerYear:'',
            contId:getIdOnboard()
          }
    )
    let handleTin = (e)=>{
        setStatus({status:'',mesage:''})
        handleInput(e)
    }  
     // eslint-disable-next-line
    let nextStep = ()=> {
        if (props.upgrade){
            props.continue()
        } else {
            var ls = getLocalStorage('individual')
            ls.employment = data
            setLocalStorage('individual', ls)
            props.continue()
        }
        
    }
    let prevStep = () => {
        if ( getLocalStorage('userInfo')?.accountType === 2 ){
            props.goBack()
        } else {
            props.back()
        }
    }
    let handleSubmit = ()=> {
        if (getLocalStorage('individual')?.employment ){
            props.continue()
        } else {
            getLocalStorage('individual')?.employment ? props.continue():  props.addDetails(getAuthApi('AddEmploymentDetails'),{...data, employerPhoneNumber:formatPhoneNumber(data.employerPhoneNumber).replace(/\s/g, ""),
            lastEmployerPhoneNumber:formatPhoneNumber(data.lastEmployerPhoneNumber).replace(/\s/g, ""),annualIncomeRange:removeCommaAmount(data.annualIncomeRange),  businessPhoneNumber:formatPhoneNumber(data.businessPhoneNumber).replace(/\s/g, "")}, nextStep)
        }
    }
    const __renderEmp = ()=> {
        switch (data.employmentStatus.toLocaleLowerCase()){
            case "unemployed":
                return (
                    <> 
                        <Singleinput name="sourceOfIncome" value={data.sourceOfIncome} onChange={handleInput} label="Source of Income" placeholder="e.g Work" disabled={false}/>
                        <span className="w-full mt-[20px]">
                            <Backcontinue hide={props.hide}  name="Continue" back={props.hide ? '' : prevStep} continue={ handleSubmit} disabled={!data.sourceOfIncome ||props.authLoad ? true:false } /> 
                        </span>
                    </>
                )
            case "student":
                return <Studentinfo  handleTin={handleTin} message={props.message} authLoad={props.authLoad} submit={handleSubmit}  status={status} setData={setData} data={data} handleInput={handleInput} handleSelect={handleSelect} back={prevStep} continue={props.continue} setStatus={setStatus} />
            case "self employed":
                return <SelfEmpinfo  handleTin={handleTin} message={props.message} authLoad={props.authLoad} verifyUserInfo={props.verifyUserInfo} submit={handleSubmit} status={status} setStatus={setStatus} data={data} handleInput={handleInput} handleSelect={handleSelect}   back={prevStep} continue={props.continue} />
            case "salaried employment":
                case "employed":
                return <Salariedinfo   verifyUserInfo={props.verifyUserInfo} handleTin={handleTin} message={props.message} authLoad={props.authLoad} submit={handleSubmit} setStatus={setStatus} data={data} handleInput={handleInput} handleSelect={handleSelect} back={prevStep} continue={props.continue} status={status} />
            case "retired":
                return <Retiredinfo  verifyUserInfo={props.verifyUserInfo} handleTin={handleTin}  message={props.message} authLoad={props.authLoad} submit={handleSubmit} setStatus={setStatus} data={data} handleInput={handleInput} handleSelect={handleSelect} back={prevStep} continue={props.continue} status={status} />
            default:
                return <></>
        }
    }

    React.useEffect(() => {
        const storedData = getLocalStorage('individual')?.employment;
        if (storedData) {
          const parsedData = getLocalStorage('individual')?.employment;
          setData(parsedData);
        }
         // eslint-disable-next-line
      }, []);
  
    return (
        <Stepwrapper title="Employment Information" check="sourceOfIncome" continue={props.continue}
            url={getAuthApi('GetUserEmploymentDetails','id')}
        >  
        <Formcontainer>
            <Selectinput
                name="employmentStatus"
                label="Employment Status"
                placeholder="Select Status"
                value={{value:data.employmentStatus, label:data.employmentStatus}}
                options={allEmps}
                onChange = {handleSelect}
            />
            {__renderEmp()}
           
        </Formcontainer>
        </Stepwrapper>     
        
    )
}

const Studentinfo= (props)=> {
   

    let data = {
        sourceOfIncome: props.data.sourceOfIncome,
        instituitionName: props.data.instituitionName,
        instituitionAddress: props.data.instituitionAddress,
        instituitonCountry: props.data.instituitonCountry,
        instituitionCity: props.data.instituitionCity,
        instituitionState: props.data.instituitionState,
    }
    return (
        <>
            <Formrow>
                <Singleinput name="sourceOfIncome" value={props.data.sourceOfIncome} onChange={props.handleInput} label="Source of Income" placeholder="e.g Work" disabled={false}/>
                <Singleinput name="instituitionName" value={props.data.instituitionName} onChange={props.handleInput} label="Instituition Name" placeholder="e.g University of Ibadan" disabled={false}/>
            </Formrow>
            <Singleinput value={props.data.instituitionAddress} name="instituitionAddress" label="Instituition Address" onChange={props.handleInput} placeholder="e.g Address" disabled={false}/>
            <Selectinput
                name="instituitonCountry"
                label="Institution Country"
                placeholder="Institution Country"
                value={{value:props.data.instituitonCountry, label:props.data.instituitonCountry}}
                options={fetchAllCountries()}
                onChange = {props.handleSelect}
            />
            <Formrow>
                <Singleinput name="instituitionCity" label="Instituition City" onChange={props.handleInput} placeholder="e.g Work" disabled={false}/>
                <Selectinput
                    name="instituitionState"
                    label="Select Institution State"
                    placeholder="Institution State"
                    value={{value:props.data.instituitionState, label:props.data.instituitionState}}
                    options={fetchAllStates(props.data.instituitonCountry)}
                    onChange = {props.handleSelect}
                />
            </Formrow>
            <Formcol>
                {
                    props.message && <AlertError body={props.message} />
                }
              
                <Backcontinue name="Continue" back={ props.back} continue={ props.submit} 
                    loading={props.authLoad} disabled={Object.values(data).some(value => value === "") || props.authLoad ? true:false }
                /> 
            </Formcol>
        </>
    )
}

const SelfEmpinfo= (props)=> {

    let data = {
        annualIncomeRange: props.data.annualIncomeRange,
        industryType: props.data.industryType,
        taxIdentificationNumber: props.data.taxIdentificationNumber,
        businessName: props.data.businessName,
        businessCountry:props.data.businessCountry,
        businessState:props.data.businessState,
        businessCity:props.data.businessCity,
        businessAddress: props.data.businessAddress,
        businessPhoneNumber: props.data.businessPhoneNumber,
    }

    return (
        <>

            
            <RegEmpTopInfo  verifyUserInfo={props.verifyUserInfo} status={props.status} setStatus={props.setStatus} empName={props.data.employerName} data={props.data} handleTin={props.handleTin} handleInput={props.handleInput} handleSelect={props.handleSelect}   type="business" />
            <Singleinput value={props.data.businessAddress}  name="businessAddress" label="Business Address" onChange={props.handleInput} placeholder="e.g Address" disabled={false}/>
            <Formrow>
                <Phonenumber
                    name="businessPhoneNumber"
                    value={props.data.businessPhoneNumber}
                    // countries ={['NG']}
                    default="NG"
                    onChange={props.handleSelect}
                />
                <Selectinput
                    name="businessCountry"
                    label="Select Business Country"
                    placeholder="Business Country"
                    value={{value:props.data.businessCountry, label:props.data.businessCountry}}
                    options={fetchAllCountries()}
                    onChange={props.handleSelect}
                />
            </Formrow>
           
            <Formrow>
                <Singleinput value={props.data.businessCity} name="businessCity" onChange={props.handleInput} label="Business City" placeholder="e.g Ikeja" disabled={false}/>
                <Selectinput
                    name="businessState"
                    label="Select Business State"
                    placeholder="Business State"
                    value={{value:props.data.businessState, label:props.data.businessState}}
                    options={fetchAllStates(props.data?.businessCountry)}
                    onChange={props.handleSelect}
                />
            </Formrow>
            <Formcol>
                {
                    props.message && <AlertError body={props.message} />
                }
                <Backcontinue name="Continue" back={ props.back} continue={ props.submit} 
                    loading={props.authLoad} disabled={Object.values(data).some(value => value === "") || props.authLoad ||(!getLocalStorage('individual')?.employment && props.status.status !=='verified') ||!isValidPhoneNumber(props.data.businessPhoneNumber) ? true:false }
                /> 
            </Formcol>
                
        </>
    )
}

const Salariedinfo= (props)=> {
    let data = {
        occupation: props.data.occupation,
        annualIncomeRange: props.data.annualIncomeRange,
        industryType: props.data.industryType,
        employerName: props.data.employerName,
        employerAddress: props.data.employerAddress,
        employerPhoneNumber: props.data.employerPhoneNumber,
        employerCountryCode: props.data.employerCountryCode,
        employerCity: props.data.employerCity,
        employerState: props.data.employerState,
        appUserId: getLocalStorage('individual')?.id
      }
    return (
        <>
            
            <RegEmpTopInfo name="employer" verifyUserInfo={props.verifyUserInfo} status={props.status} setStatus={props.setStatus} handleTin={props.handleTin} empName={props.data.employerName} data={props.data} handleInput={props.handleInput} handleSelect={props.handleSelect}  type="employer" />
            <Singleinput value={props.data.employerAddress}  name="employerAddress" label="Employer Address" onChange={props.handleInput} placeholder="e.g Address" disabled={false}/>
            <Formrow>
                <Phonenumber
                    name="employerPhoneNumber"
                    value={props.data.employerPhoneNumber}
                    // countries ={['NG']}
                    default="NG"
                    onChange={props.handleSelect}
                />
                <Selectinput
                    name="employerCountryCode"
                    label="Select Employer Country"
                    placeholder="Employer Country"
                    // value={{value:tier, label:tier}}
                    options={fetchAllCountries(true)}
                    onChange={props.handleSelect}
                />
            </Formrow>
           
            <Formrow>
                <Singleinput value={props.data.employerCity} name="employerCity" onChange={props.handleInput} label="Employer City" placeholder="e.g Ikeja" disabled={false}/>
                <Selectinput
                    name="employerState"
                    label="Select Employer State"
                    placeholder="Employer State"
                    value={{value:props.data.employerState, label:props.data.employerState}}
                    options={fetchAllStates(props.data.employerCountryCode,true)}
                    onChange={props.handleSelect}
                />
            </Formrow>
            <Formcol>
                {
                    props.message && <AlertError body={props.message} />
                }
                <Backcontinue name="Continue" back={ props.back} continue={ props.submit} 
                    disabled={Object.values(data).some(value => value === "") || props.authLoad || (!getLocalStorage('individual')?.employment && props.status.status !=='verified') ||!isValidPhoneNumber(props.data.employerPhoneNumber)  ? true:false }
                /> 
            </Formcol>
        </>
    )
}

const Retiredinfo= (props)=> {

    let data= {
        occupation: props.data.occupation,
        annualIncomeRange: props.data.annualIncomeRange,
        industryType: props.data.industryType,
        lastEmployerName: props.data.lastEmployerName,
        lastEmployerAddress:props.data.lastEmployerAddress,
        lastEmployerPhoneNumber:props.data.lastEmployerPhoneNumber,
        lastEmployerState:props.data.lastEmployerState,
        lastEmployerCity: props.data.lastEmployerCity,
        lastEmployerCountry: props.data.lastEmployerCountry,
        lastEmployerYear:props.data.lastEmployerYear,
        appUserId: getLocalStorage('individual')?.id
      }
    return (
        <>
            <Formrow>
                <Pickdate label="Last Employment Year" height={ `h-[48px]`} name="lastEmployerYear" value={props.data.lastEmployerYear} onChange={props.handleSelect}/>
            </Formrow>
            <RegEmpTopInfo name="lastEmployer"  status={props.status} setStatus={props.setStatus} verifyUserInfo={props.verifyUserInfo}  empName={props.data.lastEmployerName} data={props.data} handleTin={props.handleTin} handleInput={props.handleInput} handleSelect={props.handleSelect}   type="retired" />
            <Singleinput value={props.data.lastEmployerAddress} name="lastEmployerAddress" label="Last Employer Address" onChange={props.handleInput} placeholder="e.g Address" disabled={false}/>
            <Formrow>
                <Phonenumber
                    name="lastEmployerPhoneNumber"
                    value={props.data.lastEmployerPhoneNumber}
                    // countries ={['NG']}
                    default="NG"
                    labe="Last Employeer phone number"
                    onChange={props.handleSelect}
                />
                <Selectinput
                    name="lastEmployerCountry"
                    label="Select Employer Country"
                    placeholder="Last Employer Country"
                    value={{value:props.data.lastEmployerCountry, label:props.data.lastEmployerCountry}}
                    options={fetchAllCountries()}
                    onChange={props.handleSelect}
                />
            </Formrow>
           
            <Formrow>
                <Singleinput value={props.data.lastEmployerCity} onChange={props.handleInput} name="lastEmployerCity" label="Last Employer City" placeholder="e.g Ikeja" disabled={false}/>
                <Selectinput
                    name="lastEmployerState"
                    label="Last Select Employer State"
                    placeholder="Employer State"
                    value={{value:props.data.lastEmployerState, label:props.data.lastEmployerState}}
                    options={fetchAllStates(props.data.lastEmployerCountry)}
                    onChange={props.handleSelect}
                />
            </Formrow>
            <Formcol>
                {
                    props.message && <AlertError body={props.message} />
                }
                <Backcontinue  name="Continue" back={props.back} continue={ props.submit}  
                      disabled={Object.values(data).some(value => value === "") || props.authLoad  || (!getLocalStorage('individual')?.employment && props.status.status !=='verified') ||!isValidPhoneNumber(props.data.lastEmployerPhoneNumber) ? true:false }
                /> 
            </Formcol>
        </>
    )
}