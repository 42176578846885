import React from "react";
import { AlertErrorBtn } from "../../components/common/alertinfo";
import { removeCommaAmount, setLocalStorage } from "../../helpers/utils";
import { useDispatch, useSelector } from "react-redux";
import { profileAction } from "../../appredux/actions/common";
import { useNavigate } from "react-router-dom";
import { getTier } from "../../helpers/helpers";
import axios from "axios";
import {apiCustom } from "../../appredux/actions/authAction";

export const Checkforupgrade = (props)=> {
    // const [amount] = useState(removeCommaAmount(props.amount))
    const {user} = useSelector(state => state.profile);
    const dispatch = useDispatch()
    const amount = removeCommaAmount(props.amount)
    const navigate = useNavigate()

    user.accountTier = getTier(user?.accountTier)
    let handleUp = ()=>{

       console.log();

    //     var url =  'https://lotusapi.lotuscapitallimited.com/PaymentProof/api/PaymentProof/InvestmentTopup';
    //    const upgradeData = {
        
    //         amountPaid: amount,
    //         investmentType: "FIF",
    //         investmentUnits: 0.3,
    //         paymentRef: "stringeJ56"+Math.random(),
    //         status: true,
    //         tempContactNo: user.contId,
    //         tenor: 0
        
    //     }

    //      axios.post(url,upgradeData).then((res) => {

    //         //localStorage.setItem('upgrade',res.data.tier);
    //             console.log(res.data.tier);
        

            
    //     }).catch((err) => {
    //          console.log(err)
    //     });
        

        //console.log(amount);

        

        //var toTier = amount >19999 && amount < 40000 && user.accountTier?.toLocaleLowerCase() === 'tier one'  ?'tier two':'tier three'
        // dispatch(profileAction({modalAction:'upgrade', currTier:toTier}))
        // dispatch(profileAction({currTier:toTier}))
        // navigate('/upgradeaccount')
        // props.close()

        
    }

    let accountType = (user.accountType === 1 || user.accountType === 2)
    if (accountType && user.accountTier !== null && (amount >= 5000 && amount > 19999) && user.accountTier?.toLocaleLowerCase() === 'tier one'  ){
        return <AlertErrorBtn name="Upgrade" body={`
            Amount you entered is greater than what is allowed for ${user.accountTier}, click the button to upgrade.
        `} click={handleUp} />
    } else if (accountType &&  user.accountTier !== null && (amount >= 20000 && amount > 39999) && user.accountTier?.toLocaleLowerCase() === 'tier two'  ){
        return <AlertErrorBtn name="Upgrade" body={`
            Amount you entered is greater than what is allowed for ${user.accountTier}, click the button to upgrade.
        `} click={handleUp} />
    } else {
        // return amount + ' '+ user.accountTier
        return props.children

    }
}