import React, { useState } from "react";
import { Titlesubtitle } from "../../components/common/titlesubtitle";
import Singleinput from "../../components/common/inputs/singleinput";
import { Platformbutton } from "../../components/common/buttons";
import { Progress } from "../../components/common/progress";
import { useStep } from "../../helpers/hooks";
import { Success } from "../../components/common/success";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { resetPassword } from "../../appredux/actions/authAction";
import { useSelector } from "react-redux";
import { Passwordcheck } from "../../components/authcomp/auth";
import { Formik } from "formik";
import { ResetPassSchema } from "../../helpers/model/authschema";
import { Formcol } from "../../components/common/common";
import { AlertError } from "../../components/common/alertinfo";
import { addApiSuffix } from "../../helpers/helpers";
import { getLocalStorage, setLocalStorage } from "../../helpers/utils";

const Resetpass = (props)=> {
    const [data, setData] = useState({
        email:props.email || '',
        contId:props.contId ||  '',
        token:props.token
    })

    const {step, nextStep, prevStep,setStep}  = useStep(0)
    let { authLoad, authMessage } = useSelector(state => state.common)

    let handleInput = (e)=> {
        var name= e.target.name
        var value = e.target.value
        setData(({...data, [name]: value}))
    }

    let sendUserMail = ()=> {
        props.resetPassword(addApiSuffix('auth/forgot-password'), {email:data.email,contId:"CONT-"+data.contId}, ()=> {
            setLocalStorage('password', {contId:"CONT-"+data.contId})
            setStep(3)})
    }
    React.useEffect(()=> {
        props.token && setStep(1)
        // eslint-disable-next-line
    },[])
    switch (step) {
        case 0:
            return <Verifymail email={data.email} contId={props.contId} handleInput={handleInput}  authMessage={authMessage} authLoad={authLoad} sendUserMail={sendUserMail}  continue = {nextStep} back={prevStep} />
        // case 1:
        //     return <Verifycode data={data} setData={setData} authMessage={authMessage} authLoad={authLoad} sendUserMail={sendUserMail}  resetPassword={props.resetPassword} continue = {nextStep} back={prevStep} />
        case 1:
            return <Changepassword data={data} authMessage={authMessage} authLoad={authLoad} sendUserMail={sendUserMail}   resetPassword={props.resetPassword}  continue={nextStep} back={prevStep} />
        case 3:
            return <>
            <Progress/>
                <Success
                    title="Password Reset"
                    subtitle="We sent you an email."
                    addOns={'items-center justify-center'}
                />
            </> 
        default:
            return <></>
    }
    
}

const Verifymail = (props)=> {
    const navigate = useNavigate()

    let goToLogin = ()=> {
        navigate('/auth/login')
    }
    return (
        <div className="flex flex-col gap-[50px]">
            <Progress width={`w-[50%]`}/>
            <div className="flex flex-col gap-[30px]">
                <Titlesubtitle 
                    title="Reset Password"
                />
                <Formcol>
                    <Singleinput
                        name="email"
                        type="email"
                        value={props.email}
                        label="Email Address"
                        autoComplete="off"
                        onChange={props.handleInput}
                    />
                    <Singleinput
                        prefix="CONT-"
                        name="contId"
                        addOns="pl-[4.3em] font-[600]"
                        type="text"
                        value={props.contId}
                        label="Contact ID"
                        autoComplete="off"
                        onChange={props.handleInput}
                    />
                    
                </Formcol>
                
            </div>
            <Formcol>
                {
                    props.authMessage && <AlertError body={props.authMessage} />
                }
                <div className="w-full flex flex-col gap-[20px] items-center">
                    <Platformbutton type="normal" name="Verify Email" click={props.sendUserMail} 
                    disabled={!props.email  || props.authLoad ? true :false} loadiing={props.authLoad}/>
                    <Platformbutton type="link" text="Got an account?" title="Sign in" click={goToLogin} />
                </div>
            </Formcol>
            
        </div>
    )
}


const Changepassword = (props)=> {
    const navigate = useNavigate()

    let goToLogin = ()=> {
        localStorage.clear('password')
        navigate('/auth/login')
    }
     let handleReset = (values)=> {
        props.resetPassword(addApiSuffix('auth/reset-password'), {token:props.data.token, email:props.data.email, password:values.password,contId:getLocalStorage('password')?.contId, confirmPassword:values.cpassword}, goToLogin)
    }
    return (
        <div className="flex flex-col gap-[50px]">
            <Progress width={`w-[90%]`}/>
            <div className="flex flex-col gap-[30px]">
                <Titlesubtitle 
                    title="Reset Password"
                />

                <Formik
                    initialValues={{
                        password: '',
                        cpassword:''
                    }}
                    validationSchema={ResetPassSchema}
                    onSubmit={handleReset}
                >
                    {(formik) => {
                        const { errors, values,handleChange, handleSubmit } = formik;
                        return (
                            <form onSubmit={handleSubmit} className="flex flex-col gap-[20px]">
                                <div className="flex flex-col gap-[10px]">
                                    <Singleinput
                                        name="password"
                                        type="password"
                                        label="password"
                                        value={values.password}
                                        invalid={
                                            errors.password && values.password && 'true'
                                        }
                                        error={
                                            (errors.password &&
                                            values.password) ?errors.password:''
                                        }
                                        onChange={handleChange}
                                    />
                                    <span className="flex flex-wrap gap-[8px] mt-[10px]">
                                        <Passwordcheck name="8 Characters" check={values.password.match(/^.{8,}$/)} />
                                        <Passwordcheck name="A number" check={values.password.match(/\d/)} />
                                        <Passwordcheck name="A lowercase letter" check={values.password.match(/[a-z]/)} />
                                        <Passwordcheck name="An uppercase letter" check={values.password.match(/[A-Z]/)} />
                                        <Passwordcheck name="A Special character" check={values.password.match(/[\W_]/)} />
                                    </span>
                                </div>
                                <Singleinput
                                    name="cpassword"
                                    type="password"
                                    label="confirm password"
                                    value={values.cpassword}
                                    invalid={
                                        errors.cpassword && values.cpassword && 'true'
                                    }
                                    // error={
                                    //     (errors.cpassword &&
                                    //     touched.cpassword) ?errors.cpassword:''
                                    // }
                                    error={
                                        (errors.cpassword && values.cpassword ) && errors.cpassword
                                    }
                                    onChange={handleChange}
                                />
                                <Formcol>
                                    {
                                        props.authMessage && <AlertError body={props.authMessage} />
                                    }
                                     <div className="w-full flex flex-col gap-[20px] items-center">
                                        <Platformbutton type="submit" name={props.authLoad ? 'Reseting Password' :"Reset Password"} loading={props.authLoad} disabled={props.authLoad || !values.password || !values.cpassword ? true:false } />
                                        <Platformbutton type="link" text="Got an account?" title="Sign in" click={goToLogin} />
                                    </div>
                                </Formcol>
                               
                            </form>
                        )
                    }}
                </Formik>
            </div>

           
        </div>
    )
}

const mapStateToProps = ({ common}) => {
    const {valMessage} = common;
    return {
        valMessage,
    };
};


const mapDispatchToProps = {
    resetPassword
};
  
export default connect( mapStateToProps,mapDispatchToProps)(Resetpass);