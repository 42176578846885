import React from "react";
import { Titlesubtitle } from "../../components/common/titlesubtitle";
import Singleinput from "../../components/common/inputs/singleinput";
import { Platformbutton } from "../../components/common/buttons";
import { Progress } from "../../components/common/progress";
import { useNavigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { loginAccount } from "../../appredux/actions/authAction";
import { AlertError } from "../../components/common/alertinfo";
import { Formcol } from "../../components/common/common";
import { Formik } from "formik";
import { SignInSchema } from "../../helpers/model/authschema";

const Signincont = (props)=> {
    
    //let contId = 'UserId';

    let { authLoad, authMessage } = useSelector(state => state.common)
    const navigate = useNavigate()
   
    let goToReg = ()=> {
        navigate('/auth/register')
    }

    let goToPass = ()=> {
        navigate('/auth/reset-password')
    }

    let handleLogin = ()=> {
        props.login()
    }
     // Prevent pre-filling by setting a custom value
    const preventPreFillValue = '';

    return (
        <div className="flex flex-col gap-[50px]">

            <div className="flex flex-col gap-[30px]">
                <Titlesubtitle 
                    title="Continue from where
                    you left off"
                />
                  <Formik
                    initialValues={{
                        contId:'',
                        // email:'',
                        password:"",
                    }}
                    validationSchema={SignInSchema}
                    onSubmit={(values)=>handleLogin()}
                >
                    {(formik) => {
                        // eslint-disable-next-line
                        const { errors, touched, values,handleChange,setFieldValue, handleSubmit } = formik;
                        return (
                            <form onSubmit={handleSubmit} className="flex flex-col gap-[20px]">
                                <Singleinput
                                    //prefix="CONT-"
                                    name="contId"
                                    placeholder="CONT-ID or Email"
                                    type="text"
                                    label="User ID"
                                    value={values.contId || preventPreFillValue}
                                    autoComplete="off"
                                    invalid={
                                        errors.contId && touched.contId && 'true'
                                    }
                                    //addOns="pl-[4.3em] font-[600]"
                                    error={
                                        (errors.contId ||
                                        touched.contId) ?errors.contId:''
                                    }
                                    onChange={(e)=> {
                                        handleChange(e)
                                        props.handleInput(e)
                                    }}
                                />
                               
                                {/* <Singleinput
                                    name="email"
                                    type="email"
                                    label="Email Address"
                                    value={values.email}
                                    invalid={
                                        errors.email && touched.email && 'true'
                                    }
                                    error={
                                        (errors.email ||
                                        touched.email) ?errors.email:''
                                    }
                                    onChange={(e)=> {
                                        handleChange(e)
                                        props.handleInput(e)
                                    }}
                                /> */}
                                <div className="flex flex-col gap-[10px]">
                                    <Singleinput
                                        name="password"
                                        type="password"
                                        label="password"
                                        autoComplete="off"
                                        value={values.password}
                                        invalid={
                                            errors.password && touched.password && 'true'
                                        }
                                        error={
                                            (errors.password &&
                                            touched.password) ?errors.password:''
                                        }
                                        onChange={(e)=> {
                                            handleChange(e)
                                            props.handleInput(e)
                                        }}
                                    />
                                    <Platformbutton type="link" text="Forgot password?" title="Reset" click={goToPass} />
                                </div>
                               
                                <Formcol>
                                    {
                                        authMessage && <AlertError body={authMessage} />
                                    }
                                    <div className="w-full flex flex-col gap-[20px] items-center">
                                        <Platformbutton loading={authLoad} type="submit" name="Login" disabled={  !props.data.contId  || !props.data.password|| authLoad ? true:false } />
                                        <Platformbutton type="link" text="New user?" title="Create account" click={goToReg} />
                                    </div>
                                </Formcol>
                            </form>
                        )
                    }}
                </Formik>
                
            </div>

            
           
        </div>
    )
}

export const Twofa = (props)=> {
    const navigate = useNavigate()
    let goToLogin = ()=> {
        navigate('/auth/login')
    }
    return (
        <section className="flex flex-col gap-[30px]">
            <Progress/>
            <Titlesubtitle
                title="Two-Factor Authentication (2FA)"
                subtitle="To log in, kindly enter the verification code sent to your email address"
            />

            <span className="flex flex-col gap-[15px] mb-[20px]">
                <Singleinput
                    name="code"
                    type="number"
                    label="Code"
                    value={props.data.code}
                    onChange={props.handleInput}
                />
                {/* <Platformbutton type="link" text="Didn’t get any code?" title=" Resend code" click={props.resend} /> */}
            </span>

            <Formcol>
                {
                    props.message&& <AlertError body={props.message}  />
                }
                <div className="w-full flex flex-col items-center gap-[20px] mt-[20px]">
                <Platformbutton type="normal" name="Verify Code" click={props.click} 
                    disabled={!props.data.code ||props.loading || props.data.code < 5 ? true :false } loading={props.loading}
                />
                <Platformbutton type="link" text="Got an account?" title="Sign in" click={goToLogin} />
            </div>
            </Formcol>
            
        </section>
    )
}

const mapStateToProps = ({ common}) => {
    const {valMessage} = common;
    return {
        valMessage,
    };
};


const mapDispatchToProps = {
    loginAccount
};
  
export default connect( mapStateToProps,mapDispatchToProps)(Signincont);