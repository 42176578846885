import React from "react";
import { Routes, Route } from "react-router-dom";
import { allRoutes } from "./routes/routes";
import { ProtectedRoute } from "./routes/protectedroute";
import { Error404 } from "./pages/error";
import { QueryClient, QueryClientProvider } from 'react-query';
import { Homepage } from "./pages/homepage";
const queryClient = new QueryClient();
function App() {
  return (
    <QueryClientProvider client={queryClient}>
    <div>
        <Routes>
        <Route path="/" element={<Homepage/>} />
            {
                allRoutes.map( (item, index)=>(
                    <Route
                        key={item.path}
                        path={item.path}
                        element={
                        <ProtectedRoute auth={item.auth}>
                            <item.component />
                        </ProtectedRoute>
                        }
                    />
                  ))
            }
            <Route path="*" element={<Error404 />} />
        </Routes>
    </div>
    </QueryClientProvider>
  );
}

export default App;
