import React, { useState, useEffect } from "react";
import Singleinput from "../../components/common/inputs/singleinput";
import { Titlesubtitle } from "../../components/common/titlesubtitle";
import { Backcontinue, Platformbutton } from "../../components/common/buttons";
import { Selectinput } from "../../components/common/inputs/selectinput";
import { RedOption } from "../../helpers/data";
import { useAmount, useForm, useStep } from "../../helpers/hooks";
import { Invsummary } from "../../components/dashcomp/invcomp";
import { useSelector, useDispatch } from "react-redux";
import { Progress } from "../../components/common/progress";
import { commonAction, openNotificationWithIconRedErr,openNotificationWithIcon } from "../../appredux/actions/common";
import { Success } from "../../components/common/success";
import { Failure } from "../../components/common/failure";
import { connect } from "react-redux";
import { InvestmentAction, getAllUserInvestments, invAction, redemptionAction, newRedemptionAction } from "../../appredux/actions/invest";
import { Formcol } from "../../components/common/common";
import { AlertError } from "../../components/common/alertinfo";
import { formatterUSD, getLocalStorage, removeCommaAmount, setLocalStorage } from "../../helpers/utils";
import api, {baseUrl2} from "../../appredux/api";
import { headers } from "../../appredux/constants";
import axios from "axios";


const Redeeminv = (props)=> {

    const {step, nextStep, prevStep,setStep} = useStep(0)
    const {progress, userId} = useSelector(state => state.common)
    const {userInvs, currInv, actionLoad, actionMessage,from,allInv} = useSelector(state => state.invest)
    const {data,setData,handleInput, handleSelect}= useForm({
        id:  currInv?.id,
        type:'',
        unitToRedeem:0,
        investmentId: currInv?.invId,
        appUserId: userId,
        description: ""
    })
    const {amount,handleAmount, setAmount}= useAmount()
    currInv.amountPerUnit = allInv?.filter(item => item.code === currInv.invId)[0]?.amountPerUnit
    // console.log(currInv)
    let handleSubmit = (cb) => {


        var url = `/PaymentProof/api/Redemption/Redeem`
        //var url = data.unitToRedeem > 0 ? '/CustomerInvestment/RedeemCustomerInvestmentByUnit': data.type === 'interest' ? '/CustomerInvestment/RedeemCustomerInvestmentInterest': '/CustomerInvestment/RedeemCustomerInvestment'
        props.newRedemptionAction(url,{subAccountCode:currInv?.subCode,investmentType:currInv.code,contactNo:userId,amountoToRedeem:removeCommaAmount(amount)},function (){
                    props.getAllUserInvestments(userId)
                    let redResult = getLocalStorage('redemptionResult');
                     if(redResult === 'False'){
                        
                        setStep(3)
                     }else{
                        setStep(3)
                     }
        })
    }



    const __renderSteps = ()=> {
        switch(step){
            case 0:
                //return "hello"
                return <RedinvOne setAmount={setAmount} submit={handleSubmit} currInv={currInv}  from={from} userInvs={userInvs} data={data} setData={setData} amount={amount} message={actionMessage} loading={actionLoad} handleAmount={handleAmount} handleSelect={handleSelect} handleInput={handleInput}  id={data.id} continue={nextStep} back={prevStep} />
            
            case 1:
                    //return "hello"
                    return <RedInvUserVer setAmount={setAmount} submit={handleSubmit} currInv={currInv}  from={from} userInvs={userInvs} data={data} setData={setData} amount={amount} message={actionMessage} loading={actionLoad} handleAmount={handleAmount} handleSelect={handleSelect} handleInput={handleInput}  id={data.id} continue={nextStep} back={prevStep} />
                
            case 2:
                return <RedinvTwo currInv={currInv}  newRedemptionAction={props.newRedemptionAction} submit={handleSubmit} amount={amount} handleAmount={handleAmount} message={actionMessage} loading={actionLoad} data={data}  id={data.id} continue={nextStep} back={prevStep}/>
            
            case 3:
                return <Success
                title="Redemption Request"
                addOns={'items-center justify-center'}
                subtitle={"Congratulations! Your request to redeem your investment has been successfully submitted."}
                button="Okay, Thank you"
                click = {props.closeModal}
            />

     
            case 4:
                return <Failure
                title="Redemption Request failed"
                addOns={'items-center justify-center'}
                subtitle={"Network Error! If you do not get value within 20 mins, please try again or contact support."}
                button="Thank you"
                click = {props.closeModal}

                
            />

            case 5:
                return <Failure
                title="Redemption Request failed"
                addOns={'items-center justify-center'}
                subtitle={"The reason could be that you have exceeded your daily, weekly limit or amount requested is more than the available balance."}
                button="Thank you"
                click = {props.closeModal}

                
            />
            default:
                return <></>
        }
    } 

    useEffect(()=> {
        // props.id && setStep(1)
        // eslint-disable-next-line 
    },[])
    return (
        <div className="w-full">
            <Progress width={progress?progress:`w-[50%]`}  />
            {__renderSteps()}
        </div>
    )
}


const RedinvOne = (props)=> {
    const {step, setStep} = useStep(0);

    const dispatch = useDispatch()

    let handleCont = ()=> {        

        //var verifyUrl = "Tribe/Redemption/sendToken3";
        let userRec = getLocalStorage('individual');
        
        dispatch(invAction({actionLoad:true,actionMessage:''}))
        axios.post(baseUrl2+"api/Tribe/Redemption/sendToken3",{contId: userRec.contId,email: userRec.email},headers)
        .then((res)=>{

            if(res){
                
                api.get('https://lotusapi.lotuscapitallimited.com/PaymentProof/api/PaymentProof/GetProducts',headers)
                .then(res => {

                    setLocalStorage('halalProd',res.data[0]);
                    setLocalStorage('fifProd',res.data[1]);
                });

                    dispatch(commonAction({progress:`w-[70%]`}))
                    setStep(1)

                    dispatch(invAction({actionLoad:false,actionMessage:''}))
                    props.continue()

            return openNotificationWithIcon('Code is sent to your mail','Verification Code');
              
            }
            
        }).catch((err) => {

            return openNotificationWithIconRedErr('Try Again','Verification Code Error');

        })
       
                
                         
        
    }

    const [isButtonActive, setIsButtonActive] = useState(false);

    useEffect(() => {
        const checkTime = () => {
          const now = new Date();
          const hours = now.getHours();
          if (hours >= 8 && hours < 17) {
            setIsButtonActive(true);
          } else {
            setIsButtonActive(false);
          }
        };
    
        // Check the time when the component mounts
        checkTime();
    
        // Set an interval to check the time every minute
        const interval = setInterval(checkTime, 60000);
    
        // Clear the interval when the component unmounts
        return () => clearInterval(interval);
      }, []);


    let handleSelect = (name,value)=> {

        let inv = props.userInvs.filter(item => item.id === value.value)[0]
        dispatch(invAction({currInv:inv}))
        props.setData ({
            ...props.data, id:value.value, name:value.label, investmentId:value.invId
        })
    }

    // let handleSubmit =()=>{
    //     // props.submit(handleCont)
    //     props.continue()
    // }

    let toRedeem=(name,value)=> {
        props.setAmount(0)
        props.handleSelect(name,value)
    }
    
    React.useEffect(()=> {
        props.setData({...props.data, unitToRedeem:0})

        // eslint-disable-next-line 
    },[props.data.type])


    let balance = props.currInv?.value - removeCommaAmount(props.amount)
    //console.log(balance)
    return (
        <div className="w-full flex flex-col gap-[30px]">
            <Titlesubtitle 
                title="Redeem Investment"
                subtitle="How much would you like to redeem?"
            />

            <div className="w-full flex flex-col gap-[30px]">
                <div className="w-full flex flex-col gap-[20px]">

                   {props.from  === 'qa' && <Selectinput
                            name="id"
                            label="Select investment"
                            value={{value:props.data.id, label:props.data.name}}
                            options={props.userInvs.map(item=> (
                                {
                                    value:item.id,
                                    label:item.name,
                                    invId:item.invId
                                }
                            ))}
                            onChange = {handleSelect}
                        />}

                    <Formcol>
                        <Selectinput
                            name="type"
                            label="How do you want to redeem ?"
                            value={{value:props.data.type, label:props.data.type}}
                            options={RedOption}
                            onChange = {toRedeem}
                        />
                      {props.data.type &&( props.data.type !== 'unit'? <Formcol>
                         <Singleinput
                            currency="₦"
                            name="amount"
                            type="text"
                            label="Amount"
                            value={props.amount === 'NaN' ? 0 :props.amount}
                            placeholder="0.00"
                            onChange={props.handleAmount}
                        />
                        <p className="text-[1em] text-main-901 " >Your Investment value is <strong className="text-main-800" >₦ {formatterUSD.format(props.currInv?.value)} </strong> </p>
                        </Formcol> : <Singleinput
                        name="unitToRedeem"
                        type="number"
                        label="Unit"
                        placeholder="0.00"
                        onChange={props.handleInput}
                    />)}
                    </Formcol>
                    <Singleinput
                        name="description" label="description" placeholder="Description"
                        value={props.data.description} onChange={props.handleInput}
                    />

                    {/* Redemption token 
                    <Singleinput
                        name="description" label="description" placeholder="Description"
                        value={props.data.description} onChange={props.handleInput}
                    />*/}

                </div>
                <Formcol>
                    {props.data.type === 'unit' &&
                        (props.currInv?.unit < props.data.unitToRedeem) ? <AlertError 
                            body={'The unit you are trying to redeem is more than you have in your investment.' }/>
                    :<></>}

                    {
                        props.data.type === 'interest' &&(props.currInv?.interest < 5000 || (removeCommaAmount(props.amount)>0 && removeCommaAmount(props.amount)  < 5000) || props.currInv?.interest < removeCommaAmount(props.amount) ) ?
                        <AlertError 
                        body={props.currInv?.interest < 5000 ?'You have less than  ₦ 5,000 as interest' :
                        props.currInv?.interest < removeCommaAmount(props.amount)  ? 'The amount you are trying to redeem is more than you have as interest.': "You cannot redeem amount less than  ₦ 5,000" }/> :<></>
                        
                    }

                    {/* {
                       ( props.data.type === 'unit' || props.data.type === 'amount' ) && ( (props.currInv?.value < 5000 || (removeCommaAmount(props.amount)>0 && removeCommaAmount(props.amount)  < 5000) || props.currInv?.amount < removeCommaAmount(props.amount) || (balance < 5000 && balance > 0)  ) ? <AlertError 
                            body={props.currInv?.value < 5000 ?'You have less than  ₦ 5,000 in your plan' :
                            props.currInv?.value < removeCommaAmount(props.amount)  ? 'The amount you are trying to redeem is more than you have in your investment.':(balance < 5000 && balance > 0) ? "You cannot have an investment balance less than NGN 5,000" : "You cannot redeem amount less than NGN 5,000" }/>
                    :<></>)} 
                    
                    */}

                    {
                       ( props.data.type === 'unit' || props.data.type === 'amount' ) && ( (props.currInv?.value < 5000 || (removeCommaAmount(props.amount)>0 && removeCommaAmount(props.amount)  < 5000) ||  props.currInv?.value < removeCommaAmount(props.amount) ||   (balance < 5000 && (balance < 0 || balance > 0.99))) ? <AlertError 
                            body={props.currInv?.value < 5000 ?'You have less than  ₦ 5,000 in your plan' :
                            props.currInv?.value < removeCommaAmount(props.amount)  ? 'The amount you are trying to redeem is more than you have in your investment.':(balance < 5000 && balance > 0) ? "You cannot have an investment balance less than NGN 5,000" : "You cannot redeem amount less than NGN 5,000" }/>
                    :<></>)}

{
                      ( (removeCommaAmount(props.amount) > 100000) ? <AlertError 
                            body={props.currInv?.value < 5000 ?'You have less than  ₦ 5,000 in your plan' :
                            props.currInv?.value < removeCommaAmount(props.amount)  ? 'The amount you are trying to redeem is more than you have in your investment.':(balance < 5000 && balance > 100000) ? "You cannot have an investment balance less than NGN 5,000" : "You cannot redeem amount higher than NGN 100,000" }/>
                    :<></>)}


                    {props.message && <AlertError body={props.message} />}

                    <p className="text-orange-700">&nbsp; Note: Redemption window is opened between 8:00 AM and 5:00 PM </p>

                    {
                    props.data.type !== 'unit'? <Platformbutton loading={props.loading} name={props.loading ? "Loading..." : "Continue"} type="normal" 
                        click={handleCont}  disabled={!props.data.investmentId || !props.data.description || !isButtonActive || props.loading || 
                            props.currInv?.value < 5000 ||  ( removeCommaAmount(props.amount)  < 5000) || ( removeCommaAmount(props.amount)  > 100000) || ( props.data.type === 'amount' && (props.currInv?.value < removeCommaAmount(props.amount) )) 
                            || ( props.data.type === 'interest' && (props.currInv?.interest < removeCommaAmount(props.amount) )) ||   (balance < 5000 && (balance < 0 || balance > 0.99)) ||isNaN(removeCommaAmount(props.amount)) ? true:false}
                    />:<Platformbutton name={props.loading ? "Loading..." : "Continue"} type="normal"  loading={props.loading}
                    click={handleCont} disabled={ (props.loading ?false:true) (!props.data.unitToRedeem && !props.amount) || !props.data.investmentId || !props.data.id || !props.data.description || props.loading || (props.currInv?.unit < props.data.unitToRedeem) || props.data.unitToRedeem < 1 ||  props.currInv?.value < removeCommaAmount(props.amount) ? true:false} />
                }
                </Formcol>
            </div>
            

            
        </div>
    )
}


const RedInvUserVer = (props)=> {
    const {step, setStep} = useStep(0);
    const dispatch = useDispatch()

    let handleCont = ()=> {    
        dispatch(commonAction({progress:`w-[100%]`}))
            //props.continue()

            const halalProd = getLocalStorage('halalProd');
            const fifProd = getLocalStorage('fifProd');
            if(props.currInv?.name === 'HALAL'){
                setLocalStorage('realOfferPrice',halalProd.offer)
            }else{
                setLocalStorage('realOfferPrice',fifProd.offer)
    
            }
        
        //var verifyUrl = "Tribe/Redemption/sendToken3";
        //let userRec = getLocalStorage('individual');
        dispatch(invAction({actionLoad:true,actionMessage:''}))
        axios.post(baseUrl2+"api/Tribe/Redemption/tokenVerify3",{receivedToken: props.data.description},headers)
        .then((res)=>{

            if(res){
                
                if(res.data.status === true ){

                    openNotificationWithIcon('Token Verified Succesfully','Verification Status');
                    setStep(2)
                    
                    dispatch(invAction({actionLoad:false,actionMessage:''}))

                   return props.continue()  

                }
                if(res.data.status === false ){

                    openNotificationWithIcon('Verification Failed','Verification Status');

                    dispatch(invAction({actionLoad:false,actionMessage:''}))

                }
              
            }
            

            
        }).catch((err) => {

            return openNotificationWithIconRedErr('Try Again','Verification Code Error');

        })
                         
        
    }

 
    
    React.useEffect(()=> {
        props.setData({...props.data, unitToRedeem:0})

        // eslint-disable-next-line 
    },[props.data.type])


    let balance = props.currInv?.value - removeCommaAmount(props.amount)
    //console.log(balance)
    return (
        <div className="w-full flex flex-col gap-[30px]">
            <Titlesubtitle 
                title="User Verification"
                subtitle="A redemption Code has been sent, Please check your email"
            />

            <div className="w-full flex flex-col gap-[30px]">
                <div className="w-full flex flex-col gap-[20px]">
                  
                   
                    <Singleinput
                        name="description" label="Enter Your Verification Code" placeholder="Verification Code"
                        value={props.data.description} onChange={props.handleInput}
                    />

                </div>
                <Formcol>
                    {props.data.type === 'unit' &&
                        (props.currInv?.unit < props.data.unitToRedeem) ? <AlertError 
                            body={'The unit you are trying to redeem is more than you have in your investment.' }/>
                    :<></>}

                    {
                        props.data.type === 'interest' &&(props.currInv?.interest < 5000 || (removeCommaAmount(props.amount)>0 && removeCommaAmount(props.amount)  < 5000) || props.currInv?.interest < removeCommaAmount(props.amount) ) ?
                        <AlertError 
                        body={props.currInv?.interest < 5000 ?'You have less than  ₦ 5,000 as interest' :
                        props.currInv?.interest < removeCommaAmount(props.amount)  ? 'The amount you are trying to redeem is more than you have as interest.': "You cannot redeem amount less than  ₦ 5,000" }/> :<></>
                        
                    }

                    {
                       ( props.data.type === 'unit' || props.data.type === 'amount' ) && ( (props.currInv?.value < 5000 || (removeCommaAmount(props.amount)>0 && removeCommaAmount(props.amount)  < 5000) ||  props.currInv?.value < removeCommaAmount(props.amount) ||   (balance < 5000 && (balance < 0 || balance > 0.99))) ? <AlertError 
                            body={props.currInv?.value < 5000 ?'You have less than  ₦ 5,000 in your plan' :
                            props.currInv?.value < removeCommaAmount(props.amount)  ? 'The amount you are trying to redeem is more than you have in your investment.':(balance < 5000 && balance > 0) ? "You cannot have an investment balance less than NGN 5,000" : "You cannot redeem amount less than NGN 5,000" }/>
                    :<></>)}

{
                      ( (removeCommaAmount(props.amount) > 100000) ? <AlertError 
                            body={props.currInv?.value < 5000 ?'You have less than  ₦ 5,000 in your plan' :
                            props.currInv?.value < removeCommaAmount(props.amount)  ? 'The amount you are trying to redeem is more than you have in your investment.':(balance < 5000 && balance > 100000) ? "You cannot have an investment balance less than NGN 5,000" : "You cannot redeem amount higher than NGN 100,000" }/>
                    :<></>)}


                    {props.message && <AlertError body={props.message} />}


                    {
                    props.data.type !== 'unit'? <Platformbutton loading={props.loading} name={props.loading ? "Redeeming..." : "Continue"} type="normal" 
                        click={handleCont}  disabled={!props.data.investmentId || !props.data.description || props.loading || 
                            props.currInv?.value < 5000 ||  ( removeCommaAmount(props.amount)  < 5000) || ( removeCommaAmount(props.amount)  > 100000) || ( props.data.type === 'amount' && (props.currInv?.value < removeCommaAmount(props.amount) )) 
                            || ( props.data.type === 'interest' && (props.currInv?.interest < removeCommaAmount(props.amount) )) ||   (balance < 5000 && (balance < 0 || balance > 0.99)) ||isNaN(removeCommaAmount(props.amount)) ? true:false}
                    />:<Platformbutton name={props.loading ? "Redeeming..." : "Continue"} type="normal"  loading={props.loading}
                    click={handleCont}  disabled={(!props.data.unitToRedeem && !props.amount) || !props.data.investmentId || !props.data.id || !props.data.description || props.loading || (props.currInv?.unit < props.data.unitToRedeem) || props.data.unitToRedeem < 1 ||  props.currInv?.value < removeCommaAmount(props.amount) ? true:false} />
                }
                </Formcol>
            </div>
            

            
        </div>
    )
}


const RedinvTwo = (props)=> {

    const dispatch = useDispatch()
    let handleBack = ()=> {
        dispatch(commonAction({progress:`w-[50%]`}))
        props.back()
    }

    return (
        <div className="w-full flex flex-col gap-[30px]">
            <Titlesubtitle
                title="Transaction Summary"
                // subtitle="To finalize, kindly enter the verification code sent to your email address,
                // the code expires in 10 minutes"
            />
            <div className="w-full flex flex-col gap-[15px]">
                {/* <span className="w-full flex flex-col gap-[10px]">
                    <Pincode pin={pin} setPin={setPin} len={4} />
                    <Platformbutton type="link" text="Didn’t get any code? " title="Resend code" />
                </span> */}

                <Invsummary
                    title=""
                    data={[{title:'Investment Type',value:props.currInv?.name},
                    // {title:props.data.unitToRedeem > 0?'No of Units':` Amount `, value :props.data.unitToRedeem > 0? props.data.unitToRedeem :` ${props.amount}`},
                    {title:'Amount',value:`₦ ${props.amount}`},
                    {title:'Unit Price',value:`₦ ${(getLocalStorage('realOfferPrice') || 1)}`}, 
                    {title:`No. of Units `, value :` ${formatterUSD.format(removeCommaAmount(props.amount)/ (getLocalStorage('realOfferPrice') || 1))}`}
                ]}
                />
            </div>
            
            <Formcol>
                {
                    props.message && <AlertError body={props.message} />
                }
                 <Backcontinue loading={props.loading} name={props.loading ? 'Finalizing...' :"Finalize"} back={handleBack} 
                    disabled={props.loading ?true:false}
                    continue={props.submit}/>
            </Formcol>
           
        </div>
    )
}

const mapStateToProps = ({ common}) => {
    const {valMessage} = common;
    return {
        valMessage,
    };
};

const mapDispatchToProps = {
   newRedemptionAction,getAllUserInvestments
};
  
export default connect( mapStateToProps,mapDispatchToProps)(Redeeminv);