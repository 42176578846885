import { Aboutus, Avistores, CountDownTimer, Footer, Hero, Navbar, Process, Solutions } from "../containers/homepage/homecont"

export const Homepage = ()=> {

    return (
        <section className="w-full bg-main-100 flex flex-col items-center gap-[50px]"> 
            <Navbar/>
            <CountDownTimer/>
            <Hero/>
            <Aboutus/>
            <Process/>
            <Avistores/>
            <Solutions/>
            <Footer/>
        </section>
    )
}