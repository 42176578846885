import React from "react";
import Singleinput from "../../components/common/inputs/singleinput";
import { Selectinput } from "../../components/common/inputs/selectinput";
import { Titlesubtitle } from "../../components/common/titlesubtitle";
import { Backcontinue, Platformbutton } from "../../components/common/buttons";
import { useAmount, useForm, useStep } from "../../helpers/hooks";
import { Invsummary } from "../../components/dashcomp/invcomp";
import { AlertError, Smallinfo } from "../../components/common/alertinfo";
import { useSelector, useDispatch } from "react-redux";
import { Progress } from "../../components/common/progress";
import { commonAction } from "../../appredux/actions/common";
import { connect } from "react-redux";
import { InvestmentAction, getAllUserInvestments, getInvestment, invAction, getInvestmentTopUp } from "../../appredux/actions/invest";
import { Formcol, Formcontainer } from "../../components/common/common";
import { formatterUSD, getLocalStorage, removeCommaAmount, setLocalStorage } from "../../helpers/utils";
import { Checkforupgrade } from "./checkforupgrade";
import { topUpModel } from "../../helpers/model/investmodel";
import { PaymentBtn } from "../../components/dashcomp/payment";
import { invTenure } from "../../helpers/data";
import {openNotificationWithIconErr} from "../../appredux/actions/common";

const Topupinv = (props)=> {

    const {progress, userId} = useSelector(state => state.common)
    const {user} = useSelector(state => state.profile)
    // eslint-disable-next-line
    const {userInvs, currInv, actionLoad, actionMessage,from, allSubs, allInv} = useSelector(state => state.invest)
    const {data,setData,handleInput, handleSelect}= useForm({
        id: currInv?.id,
        investmentId: currInv?.invId,
        appUserId: userId,
        description: "",
        name:currInv?.name
    })
    const {amount,handleAmount}= useAmount(0)
    const {step, nextStep, prevStep} = useStep(0)

    const dispatch = useDispatch()

    let handleBack = ()=> {
        dispatch(commonAction({progress:`w-[50%]`}))
        prevStep()
    }

    let handleContinue = ()=> {
        dispatch(commonAction({progress:`w-[100%]`}))
        nextStep()
    }

    // let handleTopup = (ref) => {
    //     // `CustomerInvestment/TopUpCustomerInvestment`
    //     // var invs = allSubs.filter(item => item.code === currInv?.code)[0]
    //     //topup

       
    //     var payload = topUpModel({amount:removeCommaAmount(amount), code:currInv?.id, subCode:currInv?.subCode, amountPerUnit:currInv?.amountPerUnit})
    //     props.InvestmentAction(`PaymentProof/api/PaymentProof/Topup`,  {...payload,
    //         tenor:data.tenure,  
    //         investmentUnits:removeCommaAmount(formatterUSD.format(removeCommaAmount(amount)/ (currInv?.amountPerUnit || 1))), 
    //         paymentRef:ref || getLocalStorage('payment')?.paymentRef}, 
    //         function (){
    //         dispatch(commonAction({progress:``}))

    //         console.log(currInv)

    //         props.getInvestmentTopUp(currInv)
    //         props.getAllUserInvestments(userId)
    //         props.closeModal()
    //     })
    // }

    let handleTopup = (ref) => {
        // `CustomerInvestment/TopUpCustomerInvestment`
        // var invs = allSubs.filter(item => item.code === currInv?.code)[0]
        //topup
        var PaymentVerify = getLocalStorage('paymentVerify');        
        var verifiedAmount = PaymentVerify.amount/100;
        var verifiedStatus = PaymentVerify.status;
        var InvAmount = removeCommaAmount(amount);
       
        if (verifiedAmount === InvAmount && verifiedStatus === "success"){
            
            localStorage.removeItem('paymentVerify');

            var payload = topUpModel({amount:removeCommaAmount(amount), code:currInv?.id, subCode:currInv?.subCode, amountPerUnit:getLocalStorage('realOfferPrice')})
            props.InvestmentAction(`PaymentProof/api/PaymentProof/Topup`,  {...payload,
            tenor:data.tenure,  
            investmentUnits:removeCommaAmount(formatterUSD.format(removeCommaAmount(amount)/ (getLocalStorage('realOfferPrice') || 1))), 
            paymentRef:ref || getLocalStorage('payment')?.paymentRef}, 
            function (){
            dispatch(commonAction({progress:``}))      

            props.getInvestmentTopUp(currInv)
            props.getAllUserInvestments(userId)
            props.closeModal()
            
        })
            

        }else{
            
            localStorage.removeItem('paymentVerify');
            openNotificationWithIconErr("Payment Verification Failed, Contact Support","Payment Verification Error");
            props.closeModal()
            
        }    
       
        
    }
    
     currInv.amountPerUnit = allInv?.filter(item => item.code === currInv?.invId)[0]?.amountPerUnit
    // subCode={allSubs.filter(item => item.code === currInv?.code)}
    const __renderSteps = ()=> {
        switch(step){
            case 0:
                return <TopupinvOne currInv={currInv}  subCode={currInv?.subCode} close={props.closeModal} from={from} userInvs={userInvs} data={data} setData={setData} amount={amount} load={actionLoad} handleAmount={handleAmount} handleSelect={handleSelect} handleInput={handleInput}  id={data.id} continue={handleContinue} back={prevStep} />
            case 1:
                return <div className="flex flex-col gap-[30px]"> 
                {!currInv?.name?.includes('mudarabah') ? <Invsummary
                    title="Investment Summary"
                    data={[{title:'Investment Type',value:currInv?.name || data.name}, {title:'Amount',value:`₦ ${amount}`},
                    // {title:'Unit Price',value:`₦ ${allInv.filter(item => item.value === currInv?.invId)[0]?.amountPerUnit}`}, 
                    {title:'Unit Price',value:`₦ ${(getLocalStorage('realOfferPrice') || 1)}`}, 
                    {title:`No. of Units `, value :` ${formatterUSD.format(removeCommaAmount(amount)/ (getLocalStorage('realOfferPrice') || 1))}`}
                    // {title:`No. of Units `, value :` ${formatterUSD.format(removeCommaAmount(amount) / allInv.filter(item => item.value === currInv?.invId)[0]?.amountPerUnit)}`}
                ]}
                />:<Invsummary
                title="Investment Summary"
                data={[{title:'Investment Type',value:currInv?.name || data.name}, {title:'Amount',value:`₦ ${amount}`},
            ]}
            />}
                {
                   actionMessage && <AlertError body={actionMessage} />
                }
                <Backcontinue name={props.loading ? "Finalizing..." : "Finalize"} back={handleBack} loading={actionLoad}
                    continue={handleTopup}  disabled={actionLoad ? true:false} >
                        {getLocalStorage('payment')?.paymentRef ? <Platformbutton name={actionLoad ? "Finalizing..." :"Finalize"} disabled={actionLoad?true:false} type="normal" click={handleTopup} loading={actionLoad}  />:  <PaymentBtn  click={handleTopup} loading={actionLoad}  
                         data={{amount:removeCommaAmount(amount), email:user.email}} disabled={actionLoad ? true:false} />}
                    </Backcontinue>
                </div>
            default:
                return <></>
            
        }
    } 
    return (
        <div className="w-full">
            <Progress width={progress?progress:`w-[50%]`} />
            {__renderSteps()}
        </div>
    )
}


const TopupinvOne = (props)=> {
    const dispatch = useDispatch()

    let handleSelect = (name,value)=> {

        const halalProd = getLocalStorage('halalProd');
        const fifProd = getLocalStorage('fifProd');
        if(value.value === 'HALAL'){

            setLocalStorage('realOfferPrice',halalProd.offer)
        }else{
            setLocalStorage('realOfferPrice',fifProd.offer)

        }

        let inv = props.userInvs.filter(item => item.id === value.value)[0]
        dispatch(invAction({currInv:inv}))
        
        props.setData ({
            ...props.data, id:value.value, name:value.label, investmentId:value.invId
        })
    }
    return (
        <div className="w-full flex flex-col gap-[30px]">
            <Titlesubtitle 
                title="Top up Investment"
                subtitle="How much would you like to add ?"
            />
            <div className="w-full flex flex-col gap-[30px]">
                    <Formcontainer>
                        {props.from === 'qa' && <Selectinput
                            name="id"
                            label="Select investment"
                            value={{value:props.data.id, label:props.data.name}}
                            options={props.userInvs.map(item=> (
                                {
                                    value:item.id,
                                    label:item.name,
                                    invId:item.invId
                                }
                            ))}
                            onChange = {handleSelect}
                        />}
                        { props.id  && <Formcol>
                        
                            <Singleinput
                                currency="₦"
                                name="amount"
                                type="text"
                                label="Amount"
                                value={props.amount === 'NaN' ? 0 :props.amount}
                                placeholder="0.00"
                                onChange={props.handleAmount}
                            />
                            <Smallinfo body={` Minimum investment is ₦ ${props.currInv.invId ==="MT-DEP" ?"100,000": "5,000"}  ` }  />
                        </Formcol>}
                        {
                            props.currInv?.invId === "MT-DEP" &&   <Selectinput
                            name="tenure"
                            value={{value:props.data.tenure, label:invTenure.filter(item => item.value === props.data.tenure)[0]?.label}}
                            label="tenure of investment"
                            options={invTenure}
                            onChange={props.handleSelect}
                        />
                        }
                        <Singleinput
                            name="description" label="description" placeholder="Description"
                            value={props.data.description} onChange={props.handleInput}
                        />
                    </Formcontainer>
                   
                    <Checkforupgrade amount={props.amount} close={props.close}>
                        <Formcol>
                                {
                                (removeCommaAmount(props.amount) > 0 && removeCommaAmount(props.amount) < 5000) || (props.currInv.invId === "MT-DEP" && removeCommaAmount(props.amount) < 100000) ? <AlertError 
                                    body={`The Minimum amount you can top up is is ₦ ${props.currInv.invId ==="MT-DEP" ?"100,000": "5,000"} ` }/>
                            :<></>}
                                {props.message && <AlertError body={props.message} />}
                                <Platformbutton name="Continue" type="normal" click={props.continue} 
                                    disabled={Object.values(props.data).some(value => value === "") || props.load || (removeCommaAmount(props.amount) > 0 && removeCommaAmount(props.amount) < 5000) || props.subCode.length < 1  
                                    || (props.currInv.invId === "MT-DEP" && removeCommaAmount(props.amount) < 100000) ? true:false }
                                />
                        </Formcol>  
                    </Checkforupgrade>
                  
            </div>
            

            
        </div>
    )
}

const mapStateToProps = ({ common}) => {
    const {valMessage} = common;
    return {
        valMessage,
    };
};



const mapDispatchToProps = {
    InvestmentAction, getAllUserInvestments,getInvestment,getInvestmentTopUp
};
  
export default connect( mapStateToProps,mapDispatchToProps)(Topupinv);