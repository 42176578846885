import moment from "moment";
import { openNotificationWithIcon } from "../appredux/actions/common";
import { invlay,giftlay,sinvlay, invlong } from "../assets/assets";
import { headers } from "../appredux/constants";
import { allCountries, lgaList } from "./countrystate";
import { handlTableName } from "./helpers";
import {baseUrl2} from "../appredux/api";
import axios from "axios";

export const openLink = (value) => {
    window.open(value)
}
  
export const openMail = (value) => {
    window.location.href = `mailto:${value}`;
}

export const getInitials = nam => {
    const regex = /[^a-zA-Z0-9\s]/g;

    var name = nam.replace(regex, ' ');

    var initials = name.split(' ');
    initials = initials.length > 1 ? initials[0].substring(0,1) + initials[1].substring(0,1) :initials[0].substring(0,1)
    return initials
}

export const truncName = nam => {
    const regex = /[^a-zA-Z0-9\s]/g;

    var name = nam.replace(regex, ' ');

    // console.log( nam.replace(regex, ' '))
    // var initials =  name.split(' ');
    // initials = initials[0] +' '+ initials[1]?.substring?.(0,1) +'.'
    return name
    
}

export const forward = (value)  => {

   window.location = value 
    
}

export const Invsvgs = (name)=> {

    switch (name.toLocaleLowerCase()){
        case 'investment':
            return invlay
        case 'mudarabah':
            return invlay
        case 'mudarabah long':
            return invlong
        case 'giftt':
            return giftlay
        case 'single':
            return sinvlay
        default:
            return invlay
    }
}

export const indieTitle = (step)=> {

    switch (step){
        case 0:
            return "Personal Information"
        case 1:
            return "Joint Account Information"
        case 2:
            return "Address Information"
        case 3:
            return 'Bank Information'
        case 4:
            return 'Investment Information'
        case 'Single':
            return sinvlay
        default:
            return ''
    }
}

export const corpTitle = (step)=> {

    switch (step){
        case 0:
            return "Company Information"
        case 1:
            return "Contact Person"
        case 2:
            return 'Authorised Signatories'
        case 3:
            return 'Bank Information'
        case 4:
            return 'Document Verification'
        case 5:
            return 'Investment Information'
        case 6:
            return 'Payment Information'
        default:
            return ''
    }
}


// investment
export const invSummary = (data, activeInv, amount)=> {
    switch(activeInv?.label?.toLocaleLowerCase()){
        case 'lotus halal investment fund':
            case 'halal':
            return [{title:' Type',value:'Halal invesment fund'}, {title:'Amount',value:`₦ ${amount}`},
            {title:'Unit Price',value:`₦ ${activeInv?.amountPerUnit}`}, {title:`No. of Units `, value :` ${formatterUSD.format(removeCommaAmount(amount) / activeInv?.amountPerUnit)}`}]
        case 'lotus fixed investment fund':
            case 'fif':
            return [{title:' Type',value:'fixed invesment fund'}, {title:'Amount',value:`₦ ${amount}`},
            {title:'Unit Price',value:`₦ ${activeInv?.amountPerUnit}`}, {title:`No. of Units `, value :` ${formatterUSD.format(removeCommaAmount(amount) / activeInv?.amountPerUnit)}`}]
        default:
            return [{title:' Type',value:'Mudarabah Invesment'}, {title:'Amount',value:`₦ ${amount}`},
            {title:`tenure `, value :` ${data.tenure}`}]
    }
}

// handle objject error messages 
export function formatErrorMessages(errors) {
    let errorMessage = "";
  
    for (const field in errors) {
      const fieldErrors = errors[field];
      errorMessage += `${field}:\n${fieldErrors.join("\n")}\n`;
    }
    return errorMessage.trim();
  }
  
//   return all countries
export const fetchAllCountries = (code) => {

    var options =allCountries.map((item,index) => (
        !code ? {
            // id:index,
            label:item.countryName,
            value:item.countryName,
            // value:item.countryShortCode
        }:{
            label:item.countryName,
            value:item.countryShortCode
        }
    ))
    return options
};

export const getCountryCode = (value)=> {
    return allCountries.filter(item => item.countryName.toLocaleLowerCase() === value.toLocaleLowerCase())[0]?.countryShortCode
}

//   return all states
export const fetchAllStates = (country,code) => {
    var options = country ? (allCountries.filter(item => code ? ( item.countryShortCode.toLowerCase() === country.toLowerCase()): (item.countryName.toLowerCase() === country.toLowerCase())))[0].regions.map((item, index)=> (
        {
            // id:index,
            // value: item.shortCode,
            value:item.name,
            label:item.name

        }
    )):[]
    return options
};

export const fetchAllLgas = (state) => {

    var options = state ? (Object.entries(lgaList).filter(item => state.includes('Abuja') ?  item[0] === 'FCT' : item[0].toLocaleLowerCase()  === state.toLocaleLowerCase() ))[0][1]?.map((item)=> (
        {
    
            value:item,
            label:item

        }
    )):[]
    return options
};

// convert objects to formdata
export function objectToFormData(obj) {
    const formData = new FormData();
    
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        // key === 'phoneNumber'? formData.append(key, formatPhoneNumber(key[obj]).replace(/\s/g, "")):  formData.append(key, obj[key]);
        formData.append(key, obj[key]);
      }
    }
    
    return formData;
}

// check if array of objects is empty
export function isAnyDataEmpty(objectsArray) {
    for (const obj of objectsArray) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key) && obj[key] === "") {
          return true;
        }
      }
    }
    return false;
  }

export const setLocalStorage = (name,value)=> {
    var localStorage = window.localStorage;
    localStorage.setItem(name, JSON.stringify(value));
}

export const getLocalStorage = (name)=> {
    var localStorage = window.localStorage;
    var result = localStorage.getItem(name);
    // return  JSON.parse(result);
    // if (result === undefined || result === null) {
    //     return '';
    //   }
    
      return JSON.parse(result);
   
}

export const formatterUSD = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,      
    maximumFractionDigits: 2,
});

export const removeCommaAmount = (value) => {

    return parseFloat(value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace(/,/g, ''))

}

export const arrayToObject = (array)=> {
    return  array.reduce((obj, item) => {
        obj[item.key] = item.value;
        return obj;
    }, {});
}

  export  const checkAge = (bd,cb) => {
    const today = new Date();
    const inputDate = new Date(bd);
    const ageDiff = today - inputDate;
    const ageDate = new Date(ageDiff);
    const age = Math.abs(ageDate.getUTCFullYear() - 1970);

    if (age >= 18) {
      cb(false); // Enable the button
    } else {
      cb(true); // Disable the button
    }
  };

  function desObj(obj) {
    if (obj === undefined || Object.keys(obj).length === 0) {
        return {item:''}; // Return an empty object if the input object is empty
    }
    const destructured = { ...obj };
  
    return destructured;
  }

  export const getInvInfo = (type)=> {
    let inv = getLocalStorage(type)?.investment 
    let info = getLocalStorage(type)?.personal
    // let company = getLocalStorage(type)?.company 

    return {...desObj(inv), ...desObj(info)}
  }



export const Occurences = (items) => {
    const occurrencesMap = {};
    const newData = items.map(item => {
      const investmentName = item.investmentName;
      occurrencesMap[investmentName] = (occurrencesMap[investmentName] || 0) + 1;
      const modifiedName = `${investmentName} ${occurrencesMap[investmentName]}`;
      return { ...item, investmentName: modifiedName };
    });

    return newData
}

export const showError = (error)=> {


    
    if (error){
        if (error.response){
            if (error.response.data){
                if (error.response.data.message){
                    return error.response.data.message
                } else if (error.response.data.Message){
                    return error.response.data.Message
                } 
                else if (error.response.data.errors){
                    console.log(formatErrorMessages(error.response.data.errors) )
                    return formatErrorMessages(error.response.data.errors) 
                } 
                
                else {
                    return error.response.data;
                }
            }
            // else {
            //     if (error.response.data.errors){
            //         console.log(formatErrorMessages(error.response.data.errors) )
            //         return formatErrorMessages(error.response.data.errors) 
            //     }

            // }
         }else if (error.message === "Network Error"){
            return 'An error occurred, contact support'
        }
         else {
            if (error.data){
                if (error.data.errors){
                    return formatErrorMessages(error.data.errors) 
                }else if (error.data.Message){
                    return error.data.Message
                } else if (error.data.message){
                    return error.data.message
                }else {
                    return error.data
                }
            }
        } 
    }
    else {
        return 'An error occurred, contact support'
    }
}

export const capitalizeStr = (str)=> {
    return str && str.charAt(0).toUpperCase() + str.slice(1)
}

export const hideEmail = (email)  => {

    if (email) {
        const [username, domain] = email.split('@');
            const hiddenUsername = username.charAt(0) + '*'.repeat(username.length - 2) + username.charAt(username.length - 1);
            return hiddenUsername + '@' + domain;
    }
    
}

export const hidePhone = (phone)  => {

    if (phone) {
        //const [username, domain] = phone.split('@');
            const hiddenUsername = phone.charAt(0) + '*'.repeat(phone.length - 1) + phone.charAt(phone.length - 3) + phone.charAt(phone.length - 2) + phone.charAt(phone.length - 1);
            return hiddenUsername;
    }
    
}

export const getItemFromArr = (arr, search, value)=> {
    return arr.filter(item => item?.[search]?.toLocaleLowerCase()?.includes(value.toLocaleLowerCase()))
}

//  charge pre liquidation for users 
export const liqPercy = (type, amount) => {
    return type.toLocaleLowerCase() === 'pre liquidation' ?  formatterUSD.format(0.105 * amount ): 0.00
}

export const handleShare = (id,platform) => {
    const referralLink = `https://dev.lotuscapitallimited.com/auth/register?referralCode=${id}`;
    switch (platform) {
      case 'facebook':
        case 'fb':
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(referralLink)}`, '_blank');
        break;
      case 'twitter':
        window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(referralLink)}`, '_blank');
        break;
      case 'linkedin':
        window.open(
          `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(referralLink)}`,
          '_blank'
        );
        break;
      default:
        break;
    }
  };

//   return religion value
export const getReligion = (value)=>{
    switch (value.toLocaleLowerCase()){
        case 'islam':
            return 0
        default:
            return 1
    }
}

export const getCurrentYearDays = () => {
    const currentYear = new Date().getFullYear();
    const firstDayOfYear = new Date(currentYear, 0, 1);
    const lastDayOfYear = new Date(currentYear, 11, 31);
    
    const millisecondsInADay = 1000 * 60 * 60 * 24;
    const numberOfDays = Math.round((lastDayOfYear - firstDayOfYear) / millisecondsInADay) + 1;
    
    return numberOfDays;
  };

//  sequential promise
  export async function runPromSeq(promises) {
    const results = [];
    for (const promiseFn of promises) {
        try {
            const result = await promiseFn();
            results.push(result);
        } catch (error) {
            console.error(error);
            throw error
        }
    }
    return results;
}

// promises for image upload
export const runImageProm = (upload, arr)=> {
    var promises = arr.map((item, index) => () => {
        return new Promise((resolve, reject) => {
            setTimeout(async () => {
                var formdata = new FormData();
                formdata.append('file', item.value);
                try {
                    const response = await upload({ type: item.key, desc: item.key }, formdata);
                    resolve(response);
                } catch (error) {
                    reject(error);
                }
            }, index * 100);
        });
    });

    return promises
}

export const comingSoon = ()=> {

    openNotificationWithIcon('Coming Soon', "Coming soon")
}

// validate
export const getDocType = (value)=> {
    switch (value.toLocaleLowerCase()){
        case 'voterscard':
            return 0
        case 'internationalpassport':
           return 1
        case 'license':
          return 2
        default:
           return 3
    }
}
// validate
export const serviceValidation = (verifyUserInfo,data,setStatus, value)=> {

  let usrDetails = getLocalStorage('individual').personal;

    data.firstname = usrDetails.firstName;
    data.lastname = usrDetails.lastName;
    data.dob = usrDetails.dateOfBirth;

    //console.log("from main: "+ data.firstname + "  :"+data.lastname+ ": " +data.idNumber);

    switch (value.toLocaleLowerCase()){

        ///Voter card
        case 'voterscard':
            return axios.post(baseUrl2+"api/Services/ValidateVotersCard",{vin: data.idNumber,firstname:data.firstname, lastname:data.lastname, dob:data.dob},headers)
            .then((res) => {
                //status:dat.data.status.status, message:dat.message
                if(res){
                    setStatus({status:'verified',message:""});
                }

              }).catch((err) =>{

                setStatus({status:'id_mismatch',message:""});

              });

        case 'internationalpassport':
            return axios.post(baseUrl2+"api/Services/ValidateIntnPassport",{idNumber: data.idNumber,firstName:data.firstname, lastName:data.lastname},headers)
            .then((res) => {
                //status:dat.data.status.status, message:dat.message
                if(res){
                    setStatus({status:'verified',message:""});
                }

              }).catch((err) =>{

                setStatus({status:'id_mismatch',message:""});

              });

        case 'license':
            
            return axios.post(baseUrl2+"api/Services/ValidateDriversLicense",{licenseNumbe: data.idNumber,firstName:data.firstname, lastName:data.lastname},headers)
            .then((res) => {
                //status:dat.data.status.status, message:dat.message
                if(res){
                    setStatus({status:'verified',message:""});
                }

            }).catch((err) =>{

                setStatus({status:'id_mismatch',message:""});

            });

            //Nin
            case 'nin': 
                return axios.post(baseUrl2+"api/Services/ValidateNin",{nin: data.idNumber,firstname:data.firstname, lastname:data.lastname},headers)
                .then((res) => {
                    //status:dat.data.status.status, message:dat.message
                    if(res){
                        setStatus({status:'verified',message:""});
                    }

                  }).catch((err) =>{

                    setStatus({status:'id_mismatch',message:""});

                  });
                    
          
        default:
            return console.log("An Error Occured")    
        // return verifyUserInfo(`/api/Services/ValidateNin/${data.idNumber}`,{
            //     type:'doc',firstname:data.firstname, lastname:data.lastname
            // }, (value)=>setStatus({
            //     status:value.data.status.status, message:""
            // }),(dat)=> {
            //     setStatus({status:dat.data.status.status, message:dat.message})})
    }


}

export const validateEmail = (email) => {
    // This is a basic email validation regex pattern
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  export const transUrl = (url,trans, gift, id,noid, userId) => {
    const currDate = moment()
    const currentDate = currDate.format("MM-DD-YYYY")
    const prevDate = currDate.subtract(12, 'months').format("MM-DD-YYYY");
    if (gift){
        // return  `${url}${trans.startDate ? moment(trans?.startDate).format("MM-DD-YYYY"):prevDate}/${trans.endDate ? moment(trans?.endDate).format("MM-DD-YYYY"):currentDate}`
        return  `${url}`
    } else {
        var link =  url ? !id? `${url}${noid ?  '' : `?ContactNo=${userId}`}&PageIndex=${trans.pageIndex}&StartDate=${trans.startDate ? moment(trans?.startDate).format("MM-DD-YYYY"):prevDate}&EndDate=${trans.endDate ? moment(trans?.endDate).format("MM-DD-YYYY"):currentDate}`:  `${url}/${id}/${userId}/?PageIndex=${trans.pageIndex}&StartDate=${trans.startDate ? moment(trans?.startDate).format("MM-DD-YYYY"):prevDate}&EndDate=${trans.endDate ? moment(trans?.endDate).format("MM-DD-YYYY"):currentDate}`:
            `/Transaction/forinvestment/${id}?ContactNo=${userId}&PageIndex=${trans.pageIndex}&StartDate=${trans.startDate ? moment(trans?.startDate).format("MM-DD-YYYY"):prevDate}&EndDate=${trans.endDate ? moment(trans?.endDate).format("MM-DD-YYYY"):currentDate}`
        return link
        }
  }

export const sortArray = (arr,id)=> {
    var property = handlTableName(id)
    // console.log(property)
    // console.log(arr)
    return arr.slice().sort((a, b) => b[property] - a[property]);
}