import React, { useEffect, useState } from "react"
import { Passwordcheck,Singleoption, Singleoptionnew } from "../../../components/authcomp/auth"
import { Backcontinue, Platformbutton } from "../../../components/common/buttons"
import Singleinput from "../../../components/common/inputs/singleinput"
import { Formik } from 'formik';
import { SignUpSchema } from "../../../helpers/model/authschema";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAmount, useForm, useStep } from "../../../helpers/hooks"
import { Titlesubtitle } from "../../../components/common/titlesubtitle"
import { accountTypes, eDividend, ffInv, hlfInv, invTenure, mudInv } from "../../../helpers/data"
import { Singletoggle } from "../../../components/common/inputs/toggle";
import Indieonboard from "./indie/individual";
import { Invsummary, TierndBenefit } from "../../../components/dashcomp/invcomp";
import { Selectinput } from "../../../components/common/inputs/selectinput";
import { Formcontainer, Formcol, Formrow, Textcollapse } from "../../../components/common/common";
import  Corponboard from "./corp/coporate";
import { Checkbox } from "../../../components/common/inputs/checkbox";
import { Inputvalidate } from "../../../components/common/inputs/inputvalidate";
import { Progress } from "../../../components/common/progress";
import { connect, useSelector } from "react-redux";
import { createAccount, createAccountnew, resendVerification,verify2Account } from "../../../appredux/actions/authAction";
import { AlertError } from "../../../components/common/alertinfo";
import { formatterUSD, getLocalStorage, invSummary, removeCommaAmount, setLocalStorage } from "../../../helpers/utils";
import { SuccVerification, Success } from "../../../components/common/success";
// import { formatPhoneNumber } from "react-phone-number-input";
import Claimgift from "../../giftcont/claimgift";
import { getAuthApi, getIdOnboard, addApiSuffix } from "../../../helpers/helpers";
import { PaymentBtn } from "../../../components/dashcomp/payment";
import { verifyUserBankInfo,verifyBankInfo } from "../../../appredux/actions/validations";
import axios from 'axios';

const Onboardcont = (props)=> {
    const [active, setActive] = React.useState()
    const {step, nextStep, prevStep, setStep } = useStep(0)

    let handleBack = ()=> {
        setStep(1)
    }
    const [searchParams] = useSearchParams()
    let currType = searchParams.get('type')

    let handleContinue = ()=> {
        setStep(2)
    }
    // SS
    const __renderSteps = ()=> {
        switch (step){
            case 0:
           
                return <RegFirstStep setActive={setActive} active={active}  continue = {nextStep} />
            case 1:
                return <Indieonboard/>
               //return <RegSecondStep active={active}  createAccount={props.createAccount}  continue = {nextStep} back= {prevStep} setStep={setStep}/>
            case 2:
                return  <Corponboard back={handleBack} continue={handleContinue} />
               //return <Verifyaccount resendVerification={props.resendVerification} setStep={setStep} verify={props.verify2Account} active={active} />
            case 3:
                return <RegSecondStep active={active}  createAccount={props.createAccount}  continue = {nextStep} back= {prevStep} setStep={setStep}/>
               //return <Verifyaccount resendVerification={props.resendVerification} setStep={setStep} verify={props.verify2Account} active={active} />
               // return <Indieonboard/>
            case 4:
              // return <Verifyaccount resendVerification={props.resendVerification} setStep={setStep} verify={props.verify2Account} active={active} />
              return <Verifyaccount resendVerification={props.resendVerification} setStep={setStep} verify={props.verify2Account} active={active} />
            default:
                return <></>
    
        }
    }

    // SS
    // const __renderSteps = ()=> {
    //     switch (step){
    //         case 0:
    //             return <RegFirstStep setActive={setActive} active={active}  continue = {nextStep} />
    //         case 1:
    //             return <RegSecondStep active={active}  createAccount={props.createAccount}  continue = {nextStep} back= {prevStep} setStep={setStep}/>
    //         case 2:
    //             return <Verifyaccount resendVerification={props.resendVerification} setStep={setStep} verify={props.verify2Account} active={active} />
    //         case 3:
    //             return <Indieonboard/>
    //         case 4:
    //             return  <Corponboard back={handleBack} continue={handleContinue} />
    //         default:
    //             return <></>
    
    //     }
    // }
    let __renderProg = ()=> {
        switch (step){
            case 0:
                return `w-[5%]`
            case 1:
                return `w-[10%]`
            default:
                return ''
        }
    }

    let __renderView = ()=> {
        switch (currType){
            case 'individual':
                return setStep(3)
            case 'corporate':
                return setStep(4)
            case 'verification':
                return setStep(2)
            default:
                return  setStep(0)
                // setStep(0)
        }
    }

    React.useEffect(()=> {
        if (currType){
            __renderView()
        }
         // eslint-disable-next-line
    },[])

    return (
        <div className="w-full">
            {step < 2 && <Progress width={__renderProg()} />}
            {__renderSteps()}
        </div>
    )
}

export const Verifyaccount = (props)=> {
    const {data,handleInput}= useForm({
        code:''
    })
   
    const navigate = useNavigate()
   const {authLoad,authMessage} = useSelector(state=> state.common)
    let contCorporate = (data) => {
        props.setStep(4) 
        setTimeout(()=> {
            navigate(`/auth/register?type=corporate&step=company`)
        }, 100)
    }

    let contIndie = (data) => {
        props.setStep(3) 
        setTimeout(()=> {
            navigate(`/auth/register?type=individual&step=personal`)
        }, 100)
        
    }
     // eslint-disable-next-line 
    let moove = ()=> {
        props.active === 0 ?  contIndie() :contCorporate()
    }

    let handleSubmit = ()=> {
        
        let email = getLocalStorage('individual')?.email || getLocalStorage('corporate')?.email  ||  localStorage.getItem('email') || ''
        let id = getLocalStorage('individual')?.contId || getLocalStorage('corporate')?.contId  || getLocalStorage('contId')
        setLocalStorage('userInfo', {email:email, contId:id})
        let url = `/api/Auth/confirm-email`
        props.verify(url,{email: email, token:data.code, contId:id}, ()=> {
            navigate('/auth/login')
        } )
    }

    let resend = ()=> {
        let id = getLocalStorage('individual')?.contId || getLocalStorage('corporate')?.contId || getLocalStorage('contId')
        props.resendVerification(id)
     }

    return (
        <section className="flex flex-col gap-[30px]">
          
            <Progress width={`w-[20%]`} />
            <Titlesubtitle
                title="Verify Account"
                subtitle="kindly enter the verification code sent to your email address"
            />

            <span className="flex flex-col gap-[15px]">
                <Singleinput
                    name="code"
                    type="number"
                    label="Code"
                    value={data.code}
                    onChange={handleInput}
                />
                <Platformbutton type="link" text="Didn’t get any code?" title=" Resend code" click={resend} />
            </span>
            {/* {getLocalStorage('email') && ''}        */}
            <div className="flex flex-col items-center gap-[20px] mt-[20px]">
                {authMessage && <AlertError body={authMessage} />}
                <Platformbutton type="normal" name="Verify Account" click={handleSubmit} 
                   loading={authLoad} disabled={!data.code || authLoad ? true:false}
                />
            </div>
        </section>
    )
}



export const RegSecondStep = (props)=> {
    const navigate = useNavigate()
    const [toggle, setToggle] = React.useState(false)
    let { authLoad, authMessage } = useSelector(state => state.common)

    const [searchParams] = useSearchParams()
    let referral = searchParams.get('referralCode')

    let goToLogin = ()=> {
        navigate('/auth/login')
    }

    let goToReg = ()=> {
        
        navigate('/auth/register')
    }
    let contCorporate = (data) => {
        setLocalStorage('corporate', data)
        setTimeout((data)=> {
            props.setStep(4) 
             //localStorage.setItem('corporate',JSON.stringify({ id:data.companyId, cid:data.id,email:data.email}))
        }, 100)
    }

    let contIndieJoint = (data) => {
        setLocalStorage('individual', data)
        setTimeout(()=> {
            props.setStep(4) 
            navigate('/auth/register?type=verification')
             //localStorage.setItem('individual', JSON.stringify({id:data.id,email:data.email}))
        }, 100)
    }
//register user form
    let handleSubmit = (values)=> {
        const headers = {
            'Content-Type': 'multipart/form-data',
        };
        // let formdata = new FormData()
        // formdata.append('Email',values.email)
        // formdata.append('Password',values.password)
        // formdata.append('referral',referral || values.referralCode)
        const indData = JSON.parse(localStorage.getItem('individual')).personal; 
        const indBankData = JSON.parse(localStorage.getItem('bankDetails'));
        const address = JSON.parse(localStorage.getItem('address')).Addressinfo; 
        const accountType = JSON.parse(localStorage.getItem('accountType'));
        const dbBankCode = JSON.parse(localStorage.getItem('dbBankCode'));

        const newIndividual =
        {
            email: values.email,
            password: values.password,
            referral: "" ,
            firstName: indData.firstName,
            address: address.address,
            lastName: indData.lastName,
            middleName: indData.middleName ? indData.middleName : "middle",
            minorFirstName: indData.firstName,
            minorLastName: indData.lastName,
            minorMiddleName: indData.middleName ? indData.middleName : "minor middle",
            phoneNumber: indData.phoneNumber,
            bvn: indData.bvn,
            isMinor: false ? 0:1,
            dateOfBirthDmyy: indData.dateOfBirth,
            religion: indData.religion,
            stateOfOrigin: indData.stateOfOrigin,
            lga: indData.lga,
            gender: indData.gender,
            countryCode: indData.countryCode,
            emailVerified: 0,
            bankName:dbBankCode,
            accountNumber: indBankData.accountNumber,
            accountName: indData.firstName +" "+indData.lastName,
            address2: address.city,
            idNo: indData.bvn,
            city: address.state.toUpperCase()
            
          }

          console.log(newIndividual);
          //setLocalStorage('newUserRecord', newIndividual)
          //props.active = accountType;

          //console.log(newIndividual.accountName)
           var url = accountType === 0 ? getAuthApi('CreateNewUser'):  accountType === 1 ? getAuthApi('CreateNewJointAccount'): getAuthApi('AddCorporateUser')

       

        props.createAccount(url,newIndividual, (data) => {
            ( accountType === 0 || accountType === 1 )?  contIndieJoint({contId:data.data, email:newIndividual.email,}) :contCorporate({contId:data.data, email:newIndividual.email,})}, headers)

            localStorage.clear();
            // console.log(newIndividual)     
    }
    
    return (
        <section className="flex flex-col gap-[30px]">
            {/* <Progress   width={`w-[40%]`}/> */}
            <Titlesubtitle 
                title="Create Account"
            />
            <div className="flex flex-col gap-[15px] w-full">
                
                <Formik
                    initialValues={{
                        email:'',
                        password: '',
                        cpassword:''
                        //referral:''
                    }}
                    validationSchema={SignUpSchema}
                    onSubmit={(values)=>handleSubmit(values)}
                >
                    {(formik) => {
                        // eslint-disable-next-line
                        const { errors, touched, values,handleChange, handleSubmit } = formik;
                        return (
                            <form onSubmit={handleSubmit} className="flex flex-col gap-[20px]">
                         
                                
                                {/* ends */}
                                
                                <Singleinput
                                    name="email"
                                    type="email"
                                    label="Email Address"
                                    value={values.email}
                                    invalid={
                                        errors.email && touched.email && 'true'
                                    }
                                    error={
                                        (errors.email ||
                                        touched.email) ?errors.email:''
                                    }
                                    onChange={handleChange}
                                />
                                <div className="flex flex-col gap-[10px]">
                                    <Singleinput
                                        name="password"
                                        type="password"
                                        label="password"
                                        value={values.password}
                                        invalid={
                                            errors.password && values.password && 'true'
                                        }
                                        error={
                                            (errors.password &&
                                            values.password) ?errors.password:''
                                        }
                                        onChange={handleChange}
                                    />
                                    <span className="flex flex-wrap gap-[8px] mt-[10px]">
                                        <Passwordcheck name="8 Characters" check={values.password.match(/^.{8,}$/)} />
                                        <Passwordcheck name="A number" check={values.password.match(/\d/)} />
                                        <Passwordcheck name="A lowercase letter" check={values.password.match(/[a-z]/)} />
                                        <Passwordcheck name="An uppercase letter" check={values.password.match(/[A-Z]/)} />
                                        <Passwordcheck name="A Special character" check={values.password.match(/[\W_]/)} />
                                    </span>
                                </div>
                                <Singleinput
                                    name="cpassword"
                                    type="password"
                                    label="confirm password"
                                    value={values.cpassword}
                                    invalid={
                                        errors.cpassword && values.cpassword && 'true'
                                    }
                                    // error={
                                    //     (errors.cpassword &&
                                    //     touched.cpassword) ?errors.cpassword:''
                                    // }
                                    error={
                                        (errors.cpassword && values.cpassword ) && errors.cpassword
                                    }
                                    onChange={handleChange}
                                />
                                {/* <span className="flex flex-col gap-[10px]">
                                    {toggle &&
                                        <Singleinput
                                            name="referral"
                                            type="text"
                                            label="Referral Code"
                                            value={values.referral}
                                            onChange={handleChange}
                                        />
                                    }        
                                    <span className="w-full flex items-start justify-between">
                                        <p>I was reffered to by someone</p>
                                        <Singletoggle role="toggle" toggle={toggle} setToggle={setToggle} />
                                    </span>
                                </span> */}
                                
                                <div className="flex flex-col gap-[10px] items-center w-full mt-[30px]">
                                {
                                    authMessage && <AlertError body={authMessage} />
                                }
                                {/* disabled={ !values.email || !values.password  || props.loading ? true:false} */}
                                    <Backcontinue name="Create Account" back={props.back} type="submit"  loading={authLoad}  disabled={!values.email || values.password !== values.cpassword || !values.cpassword|| !values.password || authLoad ? true:false }  />
                                    <Platformbutton type="link" text="Got an account?" title="Create Account" click={goToLogin} />
                                </div>
                            </form>
                        )
                    }}
                </Formik>
            </div>

            
        </section>
    )
}


//pick accoun type
export const RegFirstStep = (props) => {

 
   const navigate = useNavigate()
    let goToLogin = ()=> {
        
        navigate('/auth/login')
    }

    return (
        <div className="flex flex-col gap-[50px]">

            <div className="flex flex-col gap-[30px]">
                <Titlesubtitle 
                    title="Account Type"
                />
                <div className="flex flex-col gap-[15px] w-full">

                {
                        
                        accountTypes.map((item,index) => (
                            
                            <Singleoptionnew
                                data={item}
                                key={index.toString()}
                                id={index}
                                active={props.active}
                                setActive={props.setActive}
                                click={props.continue}
                            />
                        )
                        
                        )
                    }

                    <Platformbutton type="link" text="Got an account? " title=" Sign in" click={goToLogin}/>
                </div>
            </div>
        </div>
    )
}

export const Regbankinfo = (props)=> {
    const [status, setStatus]= React.useState({
        status:'',
        message:''
    })
     // eslint-disable-next-line
    let {allBanks} = useSelector(state => state.common)
    let {valLoad, valType} = useSelector(state => state.common)
     // eslint-disable-next-line
    var bank =[
        {value:'044', cbncode:'ACCESS', label:'Access Bank PLC'},
        {value:'063', cbncode:'DIAMOND', label:'Diamond Bank Plc'},
        {value:'050', cbncode:'ECOBANK', label:'Ecobank Nigeria'},
        {value:'070', cbncode:'FIDELITY', label:'Fidelity Bank Plc'},
        {value:'011', cbncode:'FIRST BANK', label:'First Bank of Nigeria Plc'},
        {value:'214', cbncode:'FIRST CITY', label:'First City Monument Bank'},
        {value:'058', cbncode:'GUARANTY', label:'Guaranty Trust Bank Plc'},
        {value:'301', cbncode:'JAIZ', label:'Jaiz Bank'},
        {value:'082', cbncode:'KEYSTONE', label:'Keystone Bank Ltd'},
        {value:'303', cbncode:'LOTUS', label:'Lotus Bank Ltd'},
        {value:'101', cbncode:'PROVIDUS', label:'Providus Bank Ltd'},
        {value:'039', cbncode:'STANBIC', label:'Stanbic IBTC Plc'},
        {value:'232', cbncode:'STERLING', label:'Sterling Bank Plc'},
        {value:'032', cbncode:'UNION', label:'Union Bank of Nigeria Plc'},
        {value:'033', cbncode:'UNITED', label:'United Bank for Africa Plc'},
        {value:'215', cbncode:'UNITY', label:'Unity Bank Plc'},
        {value:'035', cbncode:'WEMA', label:'WEMA Bank Plc'},
        {value:'057', cbncode:'ZENITH', label:'Zenith Bank International'}
    ]
    let bankName = bank.filter (item => item.value === props.data.bankCode)[0]?.label


    //const userDetails = getLocalStorage('individual').personal;
    const bankData = {

        firstname: props.data.firstname,
        lastname: props.data.lastname,
        accountNumber: props.data.accountNumber,
        bankCode: props.data.bankCode,
        idType: props.data.idType,
        idNumber: props.data.idNumber,
        bankName: props.data.bankName,
        idExpiry: props.data.expiryDate

    }

    setLocalStorage('bankDetails', bankData);

    let handleDetails = ()=> {

        const selectedBank = bank.find(bank => bank.value === props.data.bankCode);

        setLocalStorage('dbBankCode', selectedBank);
        
        props.verifyBankInfo( {firstname:props.data.firstname, lastname:props.data.lastname,accountNumber:props.data.accountNumber,bankCode:props.data.bankCode},
            (dat)=> {

                props.setData({...props.data, accountName:dat.data.nuban.accountName,firstName:dat.data.nuban.firstName,lastName:dat.data.nuban.lastName,verified: dat.data.status.status})},
                ()=> setStatus({status:'id_mismatch',message:""}))
    }

    

    let handleSelect=(name,value)=> {
        // props.setData({...props.data, bankName:bank.filter(item => item.code === value), bankCode:value})

        props.setData({...props.data, accountNumber:'',bankCode:value.value, bankName: bank.filter (item => item.value === value.value)[0]?.label})
   
    }

    return (

        <Formcontainer>
            <Selectinput
                id="bank"
                name="bankName"
                label="Bank Name"
                placeholder="Select bank"
                value={{value:props.data.bankName,label:bankName}}
                options={bank}
                onChange={handleSelect}
            />
            {
             props.data.bankCode && <Inputvalidate type="bank" value={props.data.accountNumber} onChange={props.handleInput} handleInput={props.handleInput} name="accountNumber" label="Account Number" placeholder="e.g 123455678890"
                click = {handleDetails} disabled={!props.data.accountNumber || !props.data.bankName || props.data.accountNumber.length < 10 ||  props.data.accountNumber.length > 10 || (valLoad && (valType === "bank")) ? true:false  }
             />
            }
            {
                props.message && <AlertError body={props.message}/>
            }
            {status.status === 'id_mismatch' && <AlertError  body="Account mismatch" />}
           {((props.data.bankCode && props.data.verified === 'verified') && props.data.firstname) && <Formrow>
            {/* <Singleinput name="account_name" label="Account name" placeholder="e.g Sultan" value={props.data.firstname + ' '+ props.data.lastname} disabled={true}/>
             */}
             <Singleinput name="account_name" label="Account name" placeholder="e.g Sultan" value={props.data.accountName} disabled={true}/>
                <Singleinput value={props.data?.branchName} name="branchName" label="Branch Name (optional)" placeholder="Branch Name" onChange={props.handleInput} disabled={false}/>
            </Formrow>}
          {props.children}

                 

        </Formcontainer>
    )
}

export const RegInvestment =(props) => {
    const {data,handleSelect,setData } = useForm({
        investmentInstruction:'',
        investmentId:'',
        tenure:0,
        agree:false,
        code:'',
        appUserId:getIdOnboard()
    })
    let {allInv } = useSelector(state => state.invest)
    let {authMessage, authLoad} = useSelector(state => state.common)
    const {amount,setAmount,handleAmount}= useAmount(0)
    const [select,setSelect]= React.useState()

    let moveTo = (value)=>{
        if (props.corp){
            props.moveTo()
        } else {
            if (getLocalStorage('gift')){
                props.moveToUrl(value)
            } else {
                var ls = getLocalStorage('individual')
                ls.tier = value
                setLocalStorage('individual',JSON.stringify(ls))

                console.log("Moving out")
                console.log(value)
                props.moveToUrl(value)
            }
        }
    }
    let activeInv = allInv?.filter(item => item.value=== data?.investmentId)[0]
    let handleMove = ()=> {

        console.log("from the the third one: " + amount)

        var iamount = removeCommaAmount(amount || data?.amount) > 0 ? removeCommaAmount(amount  || data?.amount) :getLocalStorage('gift')?.worth 
        
        console.log("from iAMOUNT the the third one: " + iamount)
        console.log(props)
        //moveTo('tierone')

        if (((iamount >=5000) && (iamount <= 19999))){

            moveTo('tierone')
            
        } else if ((iamount > 20000) && (iamount <= 39999)){
            moveTo('tiertwo')
        }
        else if (!amount){
            moveTo('tiertwo')
        }
        else {
            moveTo('tierthree')
        }

    }
    let TandC = ()=> {

        window.open('/termsandconditions')
    }
  
    let __renderBenefit = ()=> {
        switch(activeInv?.label?.toLocaleLowerCase()){
            case 'lotus halal investment fund':
                return hlfInv
            case 'lotus fixed investment fund':
                return ffInv
            default:
                return mudInv
        }
    }
    let nextStep = ()=> {

        if (props.type){
            props.handleInvestment({...data,amount:removeCommaAmount(amount), unit:activeInv?.amountPerUnit})

        } else {
            var ls = getLocalStorage('individual')
            ls.investment = {...data,code:activeInv?.code, unit:activeInv?.amountPerUnit, amount:removeCommaAmount(amount)}
            setLocalStorage('individual', ls)
            handleMove()
        }
       
    }
    let handleSubmit = ()=> {
        // props.addDetails(`/CustomerInvestment/AddCustomerInvestment`,{
        //     appUserId:data.appUserId, code:activeInv?.code, investmentId:data.investmentId, amount:removeCommaAmount(amount) * 1, tenure:data.tenure,investmentInstruction:data.investmentInstruction
        // }, (data)=> {
        //     setLocalStorage('investmentId', data.data.id)
        //     nextStep()
        // })

        setLocalStorage('investment', {...activeInv, amount:removeCommaAmount(amount) * 1, tenure:data.tenure,investmentInstruction:data.investmentInstruction})
        nextStep()

    }
    let handleGift= (data)=> {
        setLocalStorage('gift',data)
        handleMove()
    }

    React.useEffect(() => {
        // const storedData = getLocalStorage(props.type ? 'corporate':'individual')?.investment;
        const storedData = getLocalStorage('investment');
        if (storedData) {
          setAmount(storedData.amount);
          setData(storedData);
        }
         // eslint-disable-next-line
      }, []);
    if (getLocalStorage('investment')|| getLocalStorage('gift')){
        return (
            <>
            <Success check="true" 
                subtitle="Great job!  on completing the step! To proceed, simply click the button below."
                button="Continue"
                click={handleMove}
            />
            </>
        )
    } else {

    return (
        <Formcontainer>
            <Selectinput
                name="select"
                label="Have you received a gift recently?"
                options={[{value:'no',label:'no'}, {value:'yes',label:'yes'}]}
                onChange={(name,value)=>setSelect(value.value)}
            />
           {select === 'yes' ? <Claimgift id={data.appUserId} email={getLocalStorage('userInfo')?.email} onboard={true} continue={handleGift} />
            :select &&<>
                <div className="flex flex-col gap-[20px] mb-[20px]">
                    <span className="w-full flex flex-col gap-[0px]">
                        <Selectinput
                            name="investmentId"
                            label="Select Investment"
                            placeholder="Select option"
                            value={{value:data.investmentId, label:activeInv?.label}}
                            options={allInv?.filter(item => !item.label.includes('equity'))}
                            onChange = {handleSelect}
                        />
                        {
                        (data.investmentId && !amount) &&  <TierndBenefit name={activeInv?.label}
                                popular="true" title="Package Details" 
                                data={__renderBenefit()} control ={true} mini={`Minimum Entry - ₦ ${activeInv?.code === "MT-DEP" ? "100,000":"5,000"}`}
                            />  
                        }
                    </span>
                    {data.investmentId && <>
                        <span className="w-full flex flex-col gap-[10px]">
                            <Formcol>
                            <Singleinput
                                currency="₦"
                                name="amount"
                                type="text"
                                label="Amount"
                                value={amount === 'NaN' ? 0 :amount}
                                onChange={handleAmount}
                                placeholder="0.00"
                            />
                                {
                                (((removeCommaAmount(amount) > 0 )&& (removeCommaAmount(amount) < 5000)) || (activeInv?.code === "MT-DEP" && (removeCommaAmount(amount) < 100000 && (removeCommaAmount(amount) > 0 ))   )  ) && <AlertError body={`Amount should be above ${activeInv?.code === "MT-DEP"? "N 99,999":'N 5,000' }`} />
                                }
                            </Formcol>
                            

                            {activeInv?.code?.includes('MT-DEP') &&  <Selectinput
                                name="tenure"
                                label="tenure of investment"
                                onChange={handleSelect}
                                options={invTenure}
                            />

                            }
                            {parseInt(amount) > 0 && <Invsummary
                                control = {true}
                                title="Investment Summary"
                                data={invSummary(data,activeInv,amount)}
                            />}
                        </span>
                        <Formcol>
                            <Selectinput
                                name="investmentInstruction"
                                label="How do you want your returns?"
                                placeholder="Select option"
                                value={{value:data.investmentInstruction, label:data.investmentInstruction}}
                                options={eDividend}
                                onChange = {handleSelect}
                                tip="Re-invest means ...  while redeeming means..."
                            />


                        </Formcol>
                       
                    </>}
                    

                </div>
                <span className="w-full flex flex-col gap-[10px]">
                    {
                        authMessage && <AlertError body={authMessage} />
                    }
                    <span className="flex items-center gap-[0px]">
                        <Checkbox check={data.agree} click={()=>setData({...data, agree:data.agree === true ? false: true})}  />
                        <p onClick={TandC} className="my-0 mt-[-5px] font-[500] text-[1em] text-main-901">    I consent to <strong className="text-main-800 font-[500] cursor-pointer">Terms & Conditions </strong> </p>
                    </span>
                    {/* {(activeInv?.code === "MT-DEP" && removeCommaAmount(amount) < 100000) } */}
                    {activeInv?.label === 'mudarabah investment' ?<Backcontinue name="Continue" back={props.back} continue={handleSubmit} 
                        disabled={Object.values(data).some(value => value === "") || !data.agree ? true:false }
                    /> :<Backcontinue name="Continue" back={props.back} continue={handleSubmit} loading={authLoad}
                    disabled={!amount || !data.investmentId || !data.investmentInstruction || !data.agree || authLoad || removeCommaAmount(amount) < 5000 || (activeInv?.code === "MT-DEP" && removeCommaAmount(amount) < 100000) ? true:false }
                /> }
                </span>
            </>}
            
            
        </Formcontainer>
    )}
}

export const Regpayment = (props) => {
    const {data,handleInput, handleSelect}= useForm({
        hear:'',
        others:''
    })

    const {authMessage ,authLoad} = useSelector(state => state.common)
    const {allInv} = useSelector(state => state.invest)
    // eslint-disable-next-line 
    const navigate = useNavigate()
   
    // let handlePayment =()=> {
    //     props.makePayment(`/payments`, {
    //         fullname:props.data.fullname,
    //         investmentId:getLocalStorage('investmentId'),
    //         email:props.data.email,
    //         phoneNumber:formatPhoneNumber(props.data.phone).replace(/\s/g, ""),
    //         amount:props.data.amount ? removeCommaAmount(props.data.amount)*1 :0,
    //         id:props.id,
    //         hear:data.others ? data.others : data.hear
    //     }, ()=> navigate(`/auth/login`))
    // }

    let myInvestment = getLocalStorage('investment')
    
    let handlePayment = (ref)=> {
        props.makePayment('/PaymentProof/api/PaymentProof/Invest', {
            amountPaid:removeCommaAmount(myInvestment.amount),
            investmentType: myInvestment.code,
            investmentUnits: myInvestment.amountPerUnit * myInvestment.amount ,
            paymentRef: ref || getLocalStorage('payment')?.paymentRef,
            status: true,
            tenor:myInvestment?.tenure,
            tempContactNo:getIdOnboard()
        })
    }
    return (
        <Formcontainer>
            <>
                <Formcol>
                    <Selectinput
                        name="hear"
                        label="How did you hear about us ?"
                        placeholder="Select option"
                        value={{value:data.hear, label:data.hear}}
                        options={[{value:'Facebook',label:'Facebook'},{value:'Instagram',label:'Instagram'},
                        {value:'Twitter',label:'Twitter'},{value:'Friend',label:'Friend'},{value:'Others',label:'Others'}]}
                        onChange = {handleSelect}
                    />
                   {data.hear === 'Others' && <Singleinput
                        name="others"
                        label="Others"
                        value={data.others}
                        onChange={handleInput}
                    />}
                </Formcol>
                <Invsummary
                    control = {true}
                    title="Investment Summary"
                    data={
                        [{title:'Investment Type',value:myInvestment.code}, 
                    {title:'Amount',value:`₦  ${formatterUSD.format(myInvestment.amount)}`},
                    {title:`No. of Units `, value :myInvestment.code === "MT-DEP"? 1 : formatterUSD.format(( myInvestment.amount)/ myInvestment.amountPerUnit )},
                    
                    {title:'Full Name',value:props.data.fullname},
                    {title:'email',value:props.data.email}]
                    }
                />
                <Textcollapse
                    title="Declaration"
                    body="By clicking the Make payment button, I declare that to the best of my knowledge, the information supplied in this form  and as displayed above is true and complete. 
                    I understand that the investment is not effective until payment is confirmed."
                />
                <Formcol>
                    {authMessage&& <AlertError body={authMessage} />}
                    <Backcontinue  name="Continue" back={props.back} continue={handlePayment} 
                    disabled={!data.hear || authLoad? true:false} loading={authLoad} >

                      {
                        getLocalStorage('payment')?.paymentRef ? <Platformbutton name={authLoad ? "Finalizing..." :"Finalize"} disabled={authLoad?true:false} type="normal" click={handlePayment} loading={authLoad}  />
                        : <PaymentBtn click={handlePayment} loading={authLoad}  data={{amount:myInvestment?.amount, email:getLocalStorage('userInfo')?.email}} disabled={!data.hear || authLoad? true:false} />
                      } 
                    </Backcontinue>
                </Formcol>
            </>
            
        </Formcontainer>
    )   
}

export const RegEmpTopInfo = (props)=> {
    const {amount, handleAmount} = useAmount(0)
    let {valLoad} = useSelector(state => state.common)
    let __renderTitle = ()=> {
        switch (props.type){
            case 'retired':
                return 'last'
            default:
                return ''
        }
    }
    let handleValidate=()=> {
        props.verifyUserInfo(`/api/Services/ValidateTin/${props.data.taxIdentificationNumber}`,{},
        (data)=> props.setStatus({status:data.data.status.status, message:data.message}),
        (data)=> props.setStatus({status:'failed', message:data.message}) )
    }

    let myAmount = (e)=> {
        handleAmount(e)
        props.handleInput(e)
    }

    return (
        <>
            <Formrow>
                <Selectinput
                    name="occupation"
                    label="Occupation"
                    placeholder={`Select ${__renderTitle()} Occupation`}
                    value={{value:props.data.occupation, label:props.data.occupation}}
                    options={[{value:'Accountant', label:'Accountant'},{value:'Banker', label:'Banker'}]}
                    onChange={props.handleSelect}
                />
                 <Singleinput
                    currency="₦"
                    name="annualIncomeRange" 
                    value={amount === 'NaN'? 0:amount }
                    onChange={myAmount}
                    type="text"
                    label={`${__renderTitle()} Annual Income`} placeholder="e.g 100,0000"
                />
                 {/* <Singleinput name="annualIncomeRange" type='text' value={formatterUSD.format(props.data.annualIncomeRange)} onChange={props.handleInput} label={`${__renderTitle()} Annual Income`} placeholder="e.g 100,0000" disabled={false}/> */}
                {/* <Selectinput
                    name="annualIncomeRange"
                    label={`${__renderTitle()} Annual Income`}
                    placeholder={`Select ${__renderTitle()} Annual Income`}
                    value={{value:props.data.annualIncomeRange, label:props.data.annualIncomeRange}}
                    options={[{value:'10,000 - 100,000', label:'10,000 - 100,000'},{value:'100,000 - 1,000,000', label:'100,000 - 1,000,000'}]}
                    onChange={props.handleSelect}
                /> */}
            </Formrow>
            <Formrow>
                <Selectinput
                    name="industryType"
                    label={`${__renderTitle()} Industry`}
                    placeholder={"Select Industry"}
                    value={{value:props.data.industryType, label:props.data.industryType}}
                    options={[{value:'Finance', label:'Finance'},{value:'Medicine', label:'Medicine'}]}
                    onChange={props.handleSelect}
                />
                
                {props.type === 'business' ? <Singleinput value={props.data.businessName}  name={`businessName`} label={`Business Name`} onChange={props.handleInput} placeholder="e.g " disabled={false}/>
                :<Singleinput value={props.empName}  name={`${props.name}Name`} label={`${props.name} Name`} onChange={props.handleInput} placeholder="e.g " disabled={false}/>}
            </Formrow>
            <Formcol>
                <Inputvalidate onChange={props.handleTin} handleInput={props.handleTin} name="taxIdentificationNumber" label="Tax Identification Number"
                placeholder="e.g 123455678890" disabled={!props.data.taxIdentificationNumber || valLoad || props.data.taxIdentificationNumber.length < 13 || props.data.taxIdentificationNumber.length > 13 ? true:false } 
                click={handleValidate} message="no" data={props.data} value={props.data.taxIdentificationNumber} />
                <SuccVerification type={props.status.status} />
            </Formcol>
        </>
    )
}

const mapStateToProps = ({ common}) => {
    const {valMessage} = common;
    return {
        valMessage,
    };
};


const mapDispatchToProps = {
    createAccount,verify2Account,resendVerification  
};
  
export default connect( mapStateToProps,mapDispatchToProps)(Onboardcont);