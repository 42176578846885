import React, {useState, useRef, useEffect, Link} from "react"
import { android, apple, hero, lotusw, phone } from "../../assets/assets"
import { Platformbutton } from "../../components/common/buttons"
import { footData, solutions, steps } from "../../helpers/data"
import { useNavigate } from "react-router-dom"
import { handleFooter } from "../../helpers/helpers"

export const Navbar= ()=> {
    const [open, setOpen] = React.useState(false)
    const nav = useNavigate()
    // let goTo = ()=> {
    //     window.open('https://lotuscapitallimited.com/lotuscapital/login')
    // }

    let goToLogin = ()=> {
        window.open('/auth/login')
    }
    let goToReg = ()=> {
        window.open('/auth/register')
    }

    let goHome = ()=> {
        window.open('https://lotuscapitallimited.com/')
    }
    return (
        <section className="w-full py-[30px] bg-main-700 mx-auto h-[100px] sticky left-0 right-0 top-0 z-50">
            <div className="w-full mx-auto container flex items-center justify-between">
                <img src={lotusw} alt="logo" onClick={goHome} className="w-[150px] cursor-pointer"  />
                <span className="w-[350px] hidden lg:flex  items-start gap-[15px] " >
                    {/* <Platformbutton name="Old Portal" type="secondary" click={goTo} addOns={`!border-white  !text-white`} /> */}
                    {/* <Platformbutton name="Get Started" type="normal" click={goToReg} addOns={`!bg-white  !text-main-800`} />  */}
                    {/* <Platformbutton name="Get Started" type="normal" click={goTo} addOns={`!bg-white  !text-main-800`} /> */}
                    <Platformbutton name="Login" type="secondary" click={goToLogin} addOns={`!border-white  !text-white`} />
                    <Platformbutton name="Create account" type="normal" click={goToReg} addOns={`!bg-white  !text-main-800`} /> 
                </span>
                <i onClick={()=> setOpen(!open)} className={`text-white text-[2em] flex lg:hidden ${!open?'fas fa-bars ':'fas fa-times'}`} />

                {
                    open && <div  className="w-full  lg:hidden h-[100vh] bg-main-700 absolute mx-auto top-[100px] left-0 right-0 z-0">

                            <span className="w-[90vw] mx-auto flex flex-col gap-[25px]">
                                {/* <Platformbutton name="Old Portal" type="secondary" click={goTo} addOns={`!border-white  !text-white`} /> */}
                                {/* <Platformbutton name="New Portal" type="normal" click={goToReg} addOns={`!bg-white  !text-main-800`} />  */}
                                {/* <Platformbutton name="New Portal" type="normal" click={goTo} addOns={`!bg-white  !text-main-800`} />  */}
                                <Platformbutton name="Login" type="secondary" click={goToLogin} addOns={`!border-white  !text-white`} />
                                <Platformbutton name="Create account" type="normal" click={goToReg} addOns={`!bg-white  !text-main-800`} /> 
                            </span>
                            
                        </div>
                }
            </div>

            
        </section>
    )
}

//timer

export const CountDownTimer = ()=> {

    const [timerDays, setTimerDays] = useState('00');
    const [timerHours, setTimerHours] = useState('00');
    const [timerMinutes, setTimerMinutes] = useState('00');
    const [timerSeconds, setTimerSeconds] = useState('00');

    let interval = useRef();
    let profileUpdateLink = 'https://www.lotuscapitallimited.com/eform-client-update/';

    const startTimer = () => {
        const countdownDate = new Date('June 14, 2024 00:00:00').getTime();

        interval = setInterval(() => {
            const now = new Date().getTime();
            const distance = countdownDate - now;

            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)));
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);

            if (distance < 0){
                //stop timer

                clearInterval(interval.current)
            }else{
                setTimerDays(days);
                setTimerHours(hours);
                setTimerMinutes(minutes);
                setTimerSeconds(seconds);

            }

        }, 1000);
    };

    useEffect(() => {

        startTimer();
        return () => {
            clearInterval(interval.current);
        }

    })

    return (
        
        <div>
             <div className="flex flex-col items-center gap-[10px] text-center">
                    {/* <p className="my-0 uppercase text-[1em] font-[500] text-main-700">About us</p> */}
                    <span className=" flex flex-col items-center gap-[5px] xl:max-w-[500px]">
                        <p className="my-0 text-[1.2em] leading-[1.2em] text-black">
                        Exciting News! We have Upgraded Our Portal with enhanced features to serve you better. The old portal will therefore be closed soon. <br></br><br></br>
                        In order to access the new portal, you may be required to <span  className="font-[600]">complete a one-time Know Your Customer (KYC) update.</span>  Kindly click <a class="text-slate-500 hover:text-red-600" target="_blank" href={profileUpdateLink}>here</a> to access the client profile update form.  <br></br><br></br>

                        If you encounter any challenges during the KYC update, simply send an email to <span class="text-slate-500 hover:text-red-600">info@lotuscapitallimited.com</span> for assistance. <br></br><br></br>


                        Log in today to enjoy a seamless experience!

                        </p>

                        
                        {/* <p className="my-0 mt-6 text-[1.7em] text-center leading-[1.2em] font-[500] text-main-902">
                            Countdown to Closure of Old Portal
                        </p> */}
                    <br></br>
                    </span>
                    
                </div>

        
        {/* <div className="grid grid-flow-col mx-8 mr-8 gap-5 text-center auto-cols-max">
                       <p></p>
            <div className="flex flex-col p-2 bg-neutral rounded-box border-box border-rose-700 rounded-lg border-4 text-neutral-content">
               
                <span className="countdown font-mono text-5xl">
                <span className="my-0 text-[1.5em] leading-[1.2em]  font-[700] text-main-902">
                    {timerDays}
                    </span>
                </span>
                <span className="text-main-700 font-[700] italic">
                    Days
                    </span>
                
            </div> 
            
            <div className="flex flex-col p-2 bg-neutral rounded-box border-box border-rose-700 rounded-lg border-4 text-neutral-content">
                <span className="countdown font-mono text-5xl">
                <span className="my-0 text-[1.5em] leading-[1.2em]  font-[700] text-main-902">
                    {timerHours}
                    </span>
                </span>
                <span className="text-main-700 font-[700] italic">
                    Hours
                    </span>
                
            </div>

            <div className="flex flex-col p-2 bg-neutral rounded-box border-box border-rose-700 rounded-lg border-4 text-neutral-content">
                <span className="countdown font-mono text-5xl">
                <span className="my-0 text-[1.5em] leading-[1.2em]  font-[700] text-main-902">
                    {timerMinutes}
                    </span>
                </span>
                <span className="text-main-700 font-[700] italic">
                    Minutes
                    </span>
                
            </div>

            <div className="flex flex-col p-2 bg-neutral rounded-box border-box border-rose-700 rounded-lg border-4 text-neutral-content">
                <span className="countdown font-mono text-5xl">
                <span className="my-0 text-[1.5em] leading-[1.2em]  font-[700] text-main-902">
                    {timerSeconds}
                    </span>
                </span>
                <span className="text-main-700 font-[700] italic">
                    Seconds
                    </span>
                
            </div>
            </div> */}
            </div>
    )
}

export const Hero = ()=> {
    let goToReg = ()=> {
        window.open('/auth/register')
    }

    let goToPortal = ()=> {
        window.open('https://portal.lotuscapitallimited.com/')
    }

    return (
        <section className="w-full  py-[100px] lg:py-0 lg:h-[100vh] flex items-center justify-center">
            <div className="w-full mx-auto container flex flex-col gap-[50px] lg:gap-0 lg:flex-row lg:items-center justify-between">
                <span className="flex flex-col items-start gap-[15px]">
                    <h5 className="my-0 text-[3em] leading-[1.2em]  font-[700] text-main-902 ">
                    Earn profit without compromising
                        <strong className="text-main-700 font-[700] italic"> your values </strong>
                    </h5>
                    <p className="my-0 font-[400] text-main-902 text-[1.25em]">
                    Lotus Capital Limited is a full-service ethical investment management company specializing in Asset Management, Private Wealth Management, and Financial Advisory services
                    </p>

                    <span className="w-[200px] mt-[20px]" >
                        <Platformbutton name="Start Investing" type="normal" click={goToReg} />
                         </span>
                </span>
                <img src={hero} className="w-[550px]" alt="hero"/>
            </div>

        </section>
    )
}

export const Aboutus = ()=> {

    return (
        <section className="w-full py-[100px] flex items-center justify-center">
            <div className="container flex flex-col items-center gap-[50px]">
                <div className="flex flex-col items-center gap-[10px] text-center">
                    <p className="my-0 uppercase text-[1em] font-[500] text-main-700">About us</p>
                    <span className=" flex flex-col items-center gap-[5px] xl:max-w-[500px]">
                        <p className="my-0 text-[1.5em] leading-[1.2em] font-[700] text-main-700">Lotus Capital Limited is a full-service ethical investment management</p>
                        <p className="my-0 text-[1em] font-[400] text-main-902">specializing in Asset Management, Private Wealth Management, and Financial Advisory services.</p>
                    </span>
                </div>
          
                <div className="w-[80vw] md:w-[450px]  lg:w-[950px] h-[400px] overflow-clip rounded-[20px] bg-white border-[7px] border-main-700 ">
                    <iframe
                        className="w-full h-full"
                        src="https://www.youtube.com/embed/Gu4y3171Ga8"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                    ></iframe>
                </div>
            </div>
        </section>
    )
}


export const Process = ()=> {

    return (
        <section className="w-full py-[100px]  flex items-center justify-center">
            <div className="container flex flex-col items-center gap-[50px]">
                <div className="flex flex-col items-center gap-[10px] text-center">
                    <p className="my-0 capitalize text-[2em] font-[500] text-main-700">Get Started</p>
                    <p className="my-0 text-[1em] font-[400] text-main-902">Embark on your financial journey in a few simple steps and unlock a world of possibilities.</p>
                </div>
          
               <div className="w-full grid grid-cols-1 md:grid-cols-2  xl:grid-cols-3 gap-[30px]">
                {
                    steps.map((item,index)=> (
                        <div className="w-full col-span-1 flex flex-col lg:flex-row gap-[10px]">
                            <p className="my-0 w-[50px] whitespace-nowrap h-[50px] font-[500] text-main-800 flex items-center justify-center bg-main-200 rounded-[5px]">
                                0{index + 1}
                            </p>
                            <span className="w-full flex flex-col gap-[5px]">
                                <p className="my-0 text-[1.5em] capitalize text-main-800 font-[500]">{item.name}</p>
                                <p className="my-0 text-[1em] font-[300] text-main-901 ">{item.body}</p>
                            </span>
                        </div>
                    ))
                }

               </div>
            </div>
        </section>
    )
}

export const Footer = ()=> {
    const d = new Date();
  let year = d.getFullYear();
  const nav= useNavigate()
    return (
        <section  className="w-full bg-main-700 py-[80px] flex flex-col items-start gap-[50px]">
            <div className="w-full mx-auto container flex flex-col gap-[20px] lg:gap-0 lg:flex-row items-start justify-between">
                {
                    footData.map((item,index)=> (
                        <ul className="list-none text-white flex flex-col items-start gap-[10px] xl:max-w-[350px] ">
                            <li className="uppercase text-[1.1em] text-white font-[500] mb-[10px]"> {item.name}</li>
                            
                            {
                                item.sub.map((item,index)=> (
                                    <li key={index} className="text-[1em] font-[300] cursor-pointer" onClick={()=>handleFooter(item,nav)} >{item}</li>
                                ))
                            }
                        </ul>
                    ))
                }
            </div>

            <div className="w-full mx-auto container flex flex-col items-start gap-[50px]">
                <span className="flex flex-col items-start gap-[30px]">
                    <img src={lotusw} className="w-[180px]  lg:w-[200px]" alt="logo"/>
                    <span className="flex items-start gap-[10px]">
                        <img src={android} className="w-[120px]  md:w-[150px]  xl:w-[180px]" alt="Store"/>
                        <img src={apple} className="w-[120px]  md:w-[150px]  xl:w-[180px]" alt="Store"/>
                    </span>
                </span>
                <span className="flex flex-col items-start gap-[10px]">
                    <p className="text-[1em] font-[300] text-main-100 ">
                    Lotus Capital Limited is a full-service ethical investment management company specializing in Asset Management, Private Wealth Management, and Financial Advisory services. Lotus management company specializes in Asset Management, Private Wealth Management, and Financial Advisory services.
                    </p>
                    <p className="text-[1em] text-main-902 ">Copyrights {year} Lotus Capital Limited</p>
                </span>
            </div>
        </section>
    )
}

export const Avistores = ()=> {

    return (<section className="w-full bg-main-700 pt-[80px]">
             <div className="w-full mx-auto container flex flex-col lg:flex-row justify-between items-start gap-[50px]">
                <span className="flex flex-col items-center lg:items-start gap-[30px] mx-auto lg:mx-0 my-auto">
                    <p className="my-0 font-[600] text-[2em] text-center lg:text-left leading-[1.2em] md:text-[2.5em] md:max-w-[350px] text-white ">Also available to  download on both</p>
                    <span className="flex items-start gap-[10px]">
                        <img src={android} className="w-[120px]  md:w-[150px]  xl:w-[180px]" alt="Store"/>
                        <img src={apple} className="w-[120px]  md:w-[150px]  xl:w-[180px]" alt="Store"/>
                    </span>
                </span>
               <img src={phone} alt="phone" className="w-[90vw]  xl:w-[400px]" />
            </div>
    </section>)
}

export const Solutions = ()=> {

    return (
        <section className="w-full h-fit py-[100px]">
            <div className="container mx-auto w-full">
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-x-5  gap-y-9 w-full">
                    <div className={`w-full h-[400px] px-[30px] py-[25px] lg:py-[40px] lg:px-[50px] flex flex-col gap-5 text-left items-start `}>
                        <p className="my-0 text-main-700 font-[700] text-[2.5em] md:text-[3em] xl:text-[4rem] leading-[1] max-w-full">
                            Explore our
                            Investment Solutions
                        </p>
                        <p className="my-0 text-grey-700 font-[400] text-[1.25em]">
                            Creating wealth the halal way.
                        </p>
                    </div>
                    
                    {
                        solutions.map((item,index)=> (
                            <Singlesolution
                                key={index.toString()}
                                data={item}
                                id={index}
                            />
                        ))
                    }
                </div>
            </div>
        </section>
    )
    
    
}

export const Singlesolution = ({data,id})=> {
    const [view, setView] = React.useState(false)
    let goTo = ()=> {
        window.open(data.link)
    }
    return (
        <div onClick={goTo} className={`w-full flex flex-col items-start justify-between col-span-1 h-[400px] rounded-[20px] px-[30px] py-[25px] lg:py-[40px] lg:px-[50px]  gap-7 text-left ${view ? 'bg-main-700' : 'bg-white' } `}
        onMouseEnter={()=> setView(true)} onMouseLeave={()=> setView(false)}
        >
            <span className="flex flex-col gap-[20px] lg:max-w-[300px]">
                <p className={`my-0 font-[700] text-[2em] capitalize leading-[1.2em] ${view ? 'text-white':data.color}`}>{data.name}</p>
                <p className={`my-0 font-[300] text-[1em]  ${view ? 'text-white':'text-neutral-901'}`}>
                    {data.body}
                </p>
            </span>
            <span className={`flex items-center gap-[10px]  ${view ? 'text-white':'text-main-901'} cursor-pointer `}>
                <i class="fas fa-level-up-alt text-[1.2em] rotate-90"/>
                <p className="my-0 text-[1.2em]" >Learn More</p>
            </span>
        </div>
    )
}